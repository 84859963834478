import LeftMenu from './custom/left-menu'
import RightMenu from './custom/right-menu-1'
import RootContent from './custom/root-content'
// import HomePage from './custom/homePage'
// import NotificationProcess from './custom/notificationProcess'
// import GuestFeedBack from './custom/guestFeedback'
// import CategorizeFeedback from './custom/categorizeFeedback'
import RootLoading from './custom/root-loading'
import CouponFileDashboardContainer from './custom/couponFileDashboard-container'

const AppConfig = {
  title: process.env.REACT_APP_NAME, // The Title of your React Portal
  enableOnUnloadPrompt: false, // If true, prompts user when closing the browser window. NOTE: Works on modern browsers, but each browser treats functionality differently
  enableCustomIndex: false, // If true, an index.js is required in the 'src/custom' folder, allowing logic to be performed on startup of app
  theme: null, // Leave null for default Agilit-e Theme, provide required Object (e.g. require('../custom/resources/theme').default)
  rootContent: RootLoading, // The Component to load when lauching app for the first time
  //rootContent: CouponFileDashboardContainer,
  tabNavigation: {
    enabled: false, // If true, switching components is managed via Tabs, else the Root page is always loaded with the new Component
    rootTabTitle: 'Home',
    rootTabContent: RootContent,
  },
  agilite: {
    apiServerUrl: process.env.REACT_APP_AGILITE_URL,
    apiKey: process.env.REACT_APP_AGILITE_API_KEY,
  },
  toolbar: {
    enabled: true,
    defaultState: {
      // The default state of the Toolbar when loading app for the first time
      title: process.env.REACT_APP_NAME,
      leftMenu: {
        enabled: false,
        title: 'Portal Menu',
        content: LeftMenu,
      },
      rightMenu: {
        enabled: false,
        title: 'Right Menu',
        content: RightMenu,
      },
      customMenu1: null,
      customMenu2: null,
    },
  },
}

export default AppConfig
