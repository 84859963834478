import React from 'react'
import { useDispatch } from 'react-redux'
import { Skeleton, Row, Col } from 'antd'
import { getUrlPath } from 'agilite-utils'
import SignIn from './sign-in'
import CouponFileDashboardContainer from './couponFileDashboard-container'
import CoreEnums from '../core/resources/enums'
import MemoryStore from './resources/memory-store'

export default function RootLanding() {
  const dispatch = useDispatch()

  initApp()

  function initApp() {
    const urlPath = getUrlPath()
    let content = null
    let title = ''
    let key = ''
    const closable = false

    //console.log('MemoryStore.userProfile:::RootLoading', MemoryStore.userProfile)

    if (MemoryStore.userProfile) {
      content = <CouponFileDashboardContainer />
      title = 'Coupon File Dashboard'
      key = 'coupon-file-dashboard'
    } else if (
      urlPath.length > 0 &&
      (urlPath[0] === 'contact-us' || urlPath[0] === 'contactez-nous')
    ) {
      content = <CouponFileDashboardContainer />
      title = 'Home Page'
      key = 'home'
    } else {
      //console.log('Iam here on Sign In')
      content = <SignIn />
      title = 'Sign In Form'
      key = 'login'
    }

    dispatch({
      type: CoreEnums.reducers.LOAD_CONTENT,
      payload: {
        content,
        title,
        key,
        app: key,
        closable,
        custom: {},
      },
    })
  }

  return (
    <div>
      <Row type="flex" justify="center">
        <Col xs={24} sm={16} md={12} lg={8}>
          <Skeleton active />
          <Skeleton active />
        </Col>
      </Row>
    </div>
  )
}
