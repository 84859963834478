import { connect } from 'react-redux'
import PromoCode from './promoCode'

import { callDashboard } from './resources/utilities'

const mapDispatchToProps = (dispatch) => {
  return {
    callDashboard: (Content) => {
      dispatch(callDashboard(Content))
    },
  }
}

export default connect(null, mapDispatchToProps)(PromoCode)
