import React from 'react'
import Axios from 'agilite-utils/axios'
import {
  AutoComplete,
  Input,
  Table,
  Icon,
  Button,
  Divider,
} from 'antd'
import 'bootstrap/dist/css/bootstrap.min.css'
import debounce from 'lodash/debounce'
import 'react-datepicker/dist/react-datepicker.css'
import 'antd/dist/antd.css'
import '../core/resources/style.css'
import PromoCodeCountainer from './promoCode-Container'
import './resources/style.css'
import logo from './resources/aw_logo.png'

class CouponFileDashboard extends React.Component {
  constructor(props) {
    super()
    console.log('welcome props: ', props)
    this.state = {
      couponNameEn: '',
      couponNameFr: '',
      plu: '0',
      upc: '0',
      couponCreated: '',
      status: '',
      startDate: '',
      endDate: '',
      showMyComponent: true,
      showMyTable: true,
      showSpinner: false,
      items: [],
      showsearchTable: false,
    }
    this.handleCreateDocument = this.handleCreateDocument.bind(this)
    this.handleOnchangeS = this.handleOnchangeS.bind(this)
    this.handleLoadAllDocs = this.handleLoadAllDocs.bind(this)
  }

  componentDidMount() {
    // Load on first time that access the screen
    this.handleLoadAllDocs()
  }

  handleLoadAllDocs() {
    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      headers: {
        'Content-Type': 'application/json',
        type: 'promo',
        action: 'get',
      },
    }).then((res) => {
      this.setState({
        items: res.data,
      })
    })
  }

  handleOnchangeS(value) {
    console.log('value: ', value)
    if (value) {
      Axios({
        method: 'post',
        url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
        data: {
          qry: {
            globalSearch: { $regex: value, $options: 'si' },
          },
        },
        headers: {
          'Content-Type': 'application/json',
          type: 'promo',
          action: 'get',
        },
      }).then((res) => {
        this.setState({
          items: res.data,
          showsearchTable: true,
          showMyTable: false,
        })
      })
    } else {
      this.handleLoadAllDocs()
      this.setState({
        showsearchTable: false,
        showMyTable: true,
      })
    }
  }

  handleCreateDocument(value) {
    this.props.createDocument(
      <PromoCodeCountainer data={{ value }} />,
    )
  }

  render() {
    let { sortedInfo, filteredInfo } = this.state
    sortedInfo = sortedInfo || {}
    filteredInfo = filteredInfo || {}

    const columnsGlobalSearch = [
      {
        title: 'PLU',
        key: 'plu',
        width: '7%',
        dataIndex: 'plu',
        sorter: (a, b) => a.plu - b.plu,
        sortOrder: sortedInfo.columnKey === 'plu' && sortedInfo.order,
      },
      {
        title: 'Coupon Name',
        dataIndex: 'couponNameEn',
        key: 'couponNameEn',
        width: '12%',
        // filters: this.state.couponNameEFilterList,
        // filteredValue: filteredInfo.couponNameEn || null,
        onFilter: (value, record) =>
          record.couponNameEn.includes(value),
        sorter: (a, b) =>
          a.couponNameEn.length - b.couponNameEn.length,
        sortOrder:
          sortedInfo.columnKey === 'couponNameEn' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: 'Promo Code E',
        dataIndex: 'promoCodeEn',
        key: 'promoCodeEn',
        width: '12%',
        sorter: (a, b) => {
          return a.promoCodeEn.localeCompare(b.promoCodeEn)
        },
        sortOrder:
          sortedInfo.columnKey === 'promoCodeEn' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: 'Promo Code F',
        dataIndex: 'promoCodeFr',
        key: 'promoCodeFr',
        width: '12%',
        filters: this.state.couponNameFFilterList,
        filteredValue: filteredInfo.promoCodeFr || null,
        onFilter: (value, record) =>
          record.promoCodeFr.includes(value),
        sorter: (a, b) => a.promoCodeFr.length - b.promoCodeFr.length,
        sortOrder:
          sortedInfo.columnKey === 'promoCodeFr' && sortedInfo.order,
        ellipsis: true,
        responsive: ['xs'],
      },
      {
        title: 'Region',
        dataIndex: 'region',
        key: 'region',
        width: '12%',
        // filters: this.state.upcNumberFilterList,
        // filteredValue: filteredInfo.upc || null,
        onFilter: (value, record) =>
          record.region.toString().includes(value),
        sorter: (a, b) => a.region.length - b.region.length,
        sortOrder:
          sortedInfo.columnKey === 'region' && sortedInfo.order,
        ellipsis: true /*,
          render: (text, record) => {return (<a href='#' onClick={() => this.handleEditDocument(record)}>{text}</a>)} */,
      },
    ]

    // edu
    return (
      <div>
        {/* <div style={{ width: '100%', textAlign: 'center' }}>
          <a href='https://www.aw.ca'>
            <Icon component={() => (<img src={logo} />)} />
          </a>
        </div> */}
        {/* <div className='container p-3 my-3 border shadow p-3 mb-5 rounded' style={{ background: '#fff', boarder: 'rounded' }}> */}
        {/* <h2 className='ant-col-title'>Promo Code - Dashboard</h2> */}
        <div
          className="jumbotron"
          style={{
            background: '#fff',
            paddingLeft: '15px',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          {/* <Divider /> */}
          <form>
            <div className="row">
              <AutoComplete
                dropdownClassName="certain-category-search-dropdown"
                dropdownMatchSelectWidth={500}
                style={{ width: '100%', paddingBottom: '10px' }}
                onChange={debounce(this.handleOnchangeS, 250)}
              >
                <Input.Search
                  size="default"
                  placeholder="Search Promo Code"
                />
              </AutoComplete>
            </div>
            <div className="row">
              {this.state.showsearchTable ? (
                <Table
                  rowKey="_id"
                  pagination={false}
                  size="small"
                  columns={columnsGlobalSearch}
                  dataSource={this.state.items}
                  style={{ width: '100%', paddingBottom: '10px' }}
                  scroll={{ y: 240 }}
                  onRow={(record) => ({
                    onClick: () => {
                      this.handleCreateDocument(record)
                    },
                  })}
                />
              ) : null}
            </div>

            <div
              style={{ paddingTop: '10px', paddingBottom: '10px' }}
            >
              <Button
                size="small"
                shape="round"
                style={{ borderColor: '#ff6319', color: '#ff6319' }}
                onClick={() => this.handleCreateDocument()}
              >
                {' '}
                + Promo Code
              </Button>
            </div>
          </form>

          {this.state.showMyTable ? (
            <Table
              style={{
                display: '',
                width: '100%',
                paddingBottom: '10px',
              }}
              scroll={{ y: 240 }}
              rowKey="_id"
              pagination={false}
              size="small"
              columns={columnsGlobalSearch}
              dataSource={this.state.items}
              onRow={(record) => ({
                onClick: () => {
                  this.handleCreateDocument(record)
                },
              })}
            />
          ) : null}
        </div>
        {/* <footer style={{ textAlign: 'center', color: '#ff6319' }}>Coupon File Integration</footer> */}
        {/* </div> */}
      </div>
    )
  }
}

export default CouponFileDashboard
