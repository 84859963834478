import React from 'react'
import Axios from 'agilite-utils/axios'
import {
  AutoComplete,
  Input,
  Table,
  Icon,
  message,
  Button,
  Menu,
  Popconfirm,
  Row,
  Col,
} from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import 'bootstrap/dist/css/bootstrap.min.css'
import debounce from 'lodash/debounce'
import MemoryStore from './resources/memory-store'

import 'react-datepicker/dist/react-datepicker.css'
import '../core/resources/style.css'
import moment from 'moment'
import CouponFileContainer from './couponFile-container'
import DedNumberContainer from './dedNumber-container'

import './resources/style.css'
import logo from './resources/aw_logo.png'
import PromoCodeDashboardContainer from './promoCodeDashboard-Container'

class CouponFileDashboard extends React.Component {
  constructor(props) {
    super()

    this.state = {
      createIconVisible: false,
      recordNumber: 0,
      numberOfRowsInTable: 0,
      couponNameEn: '',
      couponNameFr: '',
      tillNameEn: '',
      tillNameFr: '',
      ded: '0',
      plu: '0',
      upc: '0',
      couponCreated: '',
      couponCategory: '',
      couponCoverage: '',
      status: '',
      startDate: '',
      endDate: '',
      showCouponFile: true,
      showMyComponent: true,
      showPromoCode: false,
      dedNumberFilterList: [],
      couponFilterList: [],
      couponStatusFilterList: [],
      dedDescriptionFilterList: [],
    }
    this.handleGlobalSearch = this.handleGlobalSearch.bind(this)
    this.handleOnClickDet = this.handleOnClickDet.bind(this)

    // Generate filter lists
    this.loadListDedNumber = this.loadListDedNumber.bind(this)
    this.loadListCategory = this.loadListCategory.bind(this)
    this.loadListCouponStatus = this.loadListCouponStatus.bind(this)

    this.clearFilters = this.clearFilters.bind(this)
    this.handleOnChangeCoupon = this.handleOnChangeCoupon.bind(this)
    this.handleCreateDocument = this.handleCreateDocument.bind(this)
    this.handleCreateDed = this.handleCreateDed.bind(this)
    this.handleEditDocument = this.handleEditDocument.bind(this)
    this.handleCalculateRecordNumber = this.handleCalculateRecordNumber.bind(
      this,
    )
    this.handlePublishCouponsQSR = this.handlePublishCouponsQSR.bind(
      this,
    )
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleError = this.handleError.bind(this)
  }

  componentDidMount() {
    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      data: {
        qry: '',
        qryOptions: { sort: { couponCreated: '1' } },

        fieldsToReturn: '',
        page: null,
        pageLimit: null,
      },
      headers: {
        'Content-Type': 'application/json',
        email: MemoryStore.userProfile.profile.login,
        type: 'coupon',
        action: 'get',
      },
    })
      .then((res) => {
        this.loadListDedNumber(res.data)
        this.loadListCategory(res.data)
        this.loadListCouponStatus(res.data)
        this.handleCalculateRecordNumber(res.data)
        this.setState({
          items: res.data,
          showMyTable: false,
          showMyTable2: true,
          showMyTable3: false,
          showMyTable4: false,
          createIconVisible: true,
        })
      })
      .catch((err) => {
        console.log('Error - ', err)
      })
  }

  //Call the query for the search field, where tthe user can search for a couple of parameters
  handleGlobalSearch(value) {
    if (value && typeof value === 'string') {
      Axios({
        method: 'post',
        url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
        data: {
          qry: {
            globalSearch: { $regex: value.toString(), $options: 'i' },
          },
          qryOptions: '',
          fieldsToReturn: '',
          page: null,
          pageLimit: null,
        },
        headers: {
          'Content-Type': 'application/json',
          email: MemoryStore.userProfile.profile.login,
          type: 'coupon',
          action: 'get',
        },
      })
        .then((res) => {
          this.setState({
            items: res.data,
            showMyTable: false,
            showMyTable2: true,
            showMyTable3: false,
            showMyTable4: false,
            createIconVisible: true,
            showCouponFile: true,
            showPromoCode: false,
          })
        })
        .catch((err) => {
          console.log('Error - ', err)
        })
    } else {
      this.handleOnChangeCoupon(0)
    }
  }

  handleOnChangeCoupon(value) {
    //Call the query for all coupons by DED
    if (parseInt(value) === 0) {
      Axios({
        method: 'post',
        url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
        data: {
          qry: '',
          qryOptions: { sort: { couponCreated: '1' } },
          fieldsToReturn: '',
          page: null,
          pageLimit: null,
        },
        headers: {
          'Content-Type': 'application/json',
          email: MemoryStore.userProfile.profile.login,
          type: 'coupon',
          action: 'get',
        },
      })
        .then((res) => {
          this.loadListDedNumber(res.data)
          this.loadListCouponStatus(res.data)
          this.handleCalculateRecordNumber(res.data)
          this.setState({
            items: res.data,
            showMyTable: false,
            showMyTable2: true,
            showMyTable3: false,
            showMyTable4: false,
            showCouponFile: true,
            showPromoCode: false,
          })
        })
        .catch((err) => {
          console.log('Error - ', err)
        })
      //Call the query for all coupons
    } else if (parseInt(value) === 1) {
      Axios({
        method: 'post',
        url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
        data: {
          qry: '',
          qryOptions: { sort: { couponCreated: '1' } },
          fieldsToReturn: '',
          page: null,
          pageLimit: null,
        },
        headers: {
          'Content-Type': 'application/json',
          email: MemoryStore.userProfile.profile.login,
          type: 'coupon',
          action: 'get',
        },
      })
        .then((res) => {
          this.loadListDedNumber(res.data)
          this.loadListCouponStatus(res.data)
          this.handleCalculateRecordNumber(res.data)
          this.setState({
            items: res.data,
            showMyTable: false,
            showMyTable2: false,
            showMyTable3: true,
            showMyTable4: false,
            showCouponFile: true,
            showPromoCode: false,
          })
        })
        .catch((err) => {
          console.log('Error - ', err)
        })
      //Call the query for DED numbers
    } else if (parseInt(value) === 2) {
      Axios({
        method: 'post',
        url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
        data: {
          qry: '',
          qryOptions: { sort: { ded: '1' } },
          fieldsToReturn: '',
          page: null,
          pageLimit: null,
        },
        headers: {
          'Content-Type': 'application/json',
          email: MemoryStore.userProfile.profile.login,
          type: 'ded',
          action: 'get',
        },
      })
        .then((res) => {
          this.handleCalculateRecordNumber(res.data)
          this.setState({
            items: res.data,
            showMyTable: false,
            showMyTable2: false,
            showMyTable3: false,
            showMyTable4: true,
            showCouponFile: true,
            showPromoCode: false,
          })
        })
        .catch((err) => {
          console.log('Error - ', err)
        })
    } else if (parseInt(value) === 3) {
      this.setState({
        showMyTable: false,
        showMyTable2: false,
        showMyTable3: false,
        showMyTable4: false,
        showCouponFile: false,
        showPromoCode: true,
      })
    } else if (parseInt(value) === 4) {
      this.handlePublishCouponsQSR()
    }
  }

  loadListDedNumber(dataPayload) {
    const dedNumberFilterList = []
    const dupCheck = {}
    const tmpThis = this
    let dataEntry = null

    for (const x in dataPayload) {
      dataEntry = dataPayload[x]

      if (!dataEntry.ded) {
        dataEntry.ded = 0
      }

      if (!dupCheck[dataEntry.ded]) {
        dupCheck[dataEntry.ded] = dataEntry.ded
        dedNumberFilterList.push({
          text: dataEntry.ded,
          value: dataEntry.ded,
        })
      }
    }
    dedNumberFilterList.sort((a, b) =>
      a.text.toString().localeCompare(b.text),
    )
    tmpThis.setState({ dedNumberFilterList })
  }

  loadListCategory(dataPayload) {
    const categoryFilterList = []
    const dupCheck = {}
    const tmpThis = this
    let dataEntry = null

    for (const x in dataPayload) {
      dataEntry = dataPayload[x]

      if (!dataEntry.couponCategory) {
        dataEntry.couponCategory = 0
      }

      if (!dupCheck[dataEntry.couponCategory]) {
        dupCheck[dataEntry.couponCategory] = dataEntry.couponCategory
        categoryFilterList.push({
          text: dataEntry.couponCategory,
          value: dataEntry.couponCategory,
        })
      }
    }

    categoryFilterList.sort((a, b) =>
      a.text.toString().localeCompare(b.text),
    )
    tmpThis.setState({ categoryFilterList })
  }

  loadListCouponStatus(dataPayload) {
    const couponStatusFilterList = []
    const dupCheck = {}
    const tmpThis = this
    let dataEntry = null

    for (const x in dataPayload) {
      dataEntry = dataPayload[x]

      if (!dataEntry.status) {
        dataEntry.status = 'N/A'
      }

      if (!dupCheck[dataEntry.status]) {
        dupCheck[dataEntry.status] = dataEntry.status
        couponStatusFilterList.push({
          text: dataEntry.status,
          value: dataEntry.status,
        })
      }
    }

    couponStatusFilterList.sort((a, b) =>
      a.text.toString().localeCompare(b.text),
    )
    tmpThis.setState({ couponStatusFilterList })
  }

  handleCalculateRecordNumber(dataPayload) {
    let recordNumber = 0

    dataPayload.forEach(() => {
      recordNumber++
    })

    this.setState({
      recordNumber,
    })
  }

  handleOnClickDet(value) {
    this.setState({
      showMyTable: false,
      showMyTable2: true,
    })
  }

  state = {
    filteredInfo: null,
    sortedInfo: null,
  }

  handleChange = (
    pagination,
    filters,
    sorter,
    numberOfRowsInTable,
  ) => {
    let recordNumber = 0

    recordNumber = numberOfRowsInTable.currentDataSource.length

    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      recordNumber,
    })
  }

  clearFilters = () => {
    this.handleOnChangeCoupon(0)
    this.setState({ filteredInfo: null })
  }

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    })
  }

  handleEditDocument(record, from) {
    if (from === 'editDed') {
      const entryDed = MemoryStore.dedForm

      entryDed.dedId = record._id
      entryDed.ded = record.ded
      entryDed.dedName = record.description
      entryDed.isNewDed = false

      this.props.editDocument(
        record,
        <DedNumberContainer data={record} />,
      )
    } else {
      const entryCoupon = MemoryStore.couponForm

      entryCoupon.couponId = record._id
      entryCoupon.couponNameEn = record.couponNameEn
      entryCoupon.couponNameFr = record.couponNameFr
      entryCoupon.tillNameEn = record.tillNameEn
      entryCoupon.tillNameFr = record.tillNameFr
      entryCoupon.ded = record.ded
      entryCoupon.upc = record.upc
      entryCoupon.plu = record.plu
      entryCoupon.couponCreated = moment(record.couponCreated).format(
        'MM/DD/YYYY',
      )

      entryCoupon.couponCategory = record.couponCategory
      entryCoupon.couponCoverage = record.couponCoverage
      entryCoupon.startDate = record.startDate
      entryCoupon.endDate = record.endDate
      entryCoupon.tillInstructionsE = record.tillInstructionsE
      entryCoupon.tillInstructionsF = record.tillInstructionsF
      entryCoupon.region = record.region
      entryCoupon.criteria = record.criteria
      entryCoupon.language = record.language
      entryCoupon.fileAttachments = record.fileAttachments
      entryCoupon.fileId = record.fileId
      entryCoupon.fileName = record.fileName

      entryCoupon.status = record.status
      entryCoupon.globalSearch = record.globalSearch
      entryCoupon.updatedDate = moment(record.updatedDate).format(
        'MM/DD/YYYY',
      )
      entryCoupon.updatedBy = record.updatedBy
      entryCoupon.isNewCoupon = false
      entryCoupon.dedName = ''

      this.props.editDocument(
        record,
        <CouponFileContainer data={record} />,
      )
    }
  }

  handleCreateDocument() {
    const entryCoupon = MemoryStore.couponForm
    entryCoupon.isNewCoupon = true

    this.props.createDocument(<CouponFileContainer data={{}} />)
  }

  handleCreateDed() {
    const entryDed = MemoryStore.dedForm
    entryDed.isNewDed = true
    this.props.createDedNumber(<DedNumberContainer data={{}} />)
  }

  handlePublishCouponsQSR() {
    Axios({
      method: 'get',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/coupon/report`,
      data: {
        qry: '',
        qryOptions: '',
        fieldsToReturn: '',
        page: null,
        pageLimit: null,
      },
      headers: {
        'Content-Type': 'application/json',
        reportType: 'upcoming',
        sendTo: 'qsr',
      },
    })
      .then((res) => {
        this.handleSuccess(
          'Success - The List has been sent to QSR',
          3,
        )
      })
      .catch((err) => {
        this.handleError(
          'Error - The List has not been sent to QSR',
          3,
        )
      })
  }

  handleSuccess(msg, duration) {
    message.success(msg, duration)
  }

  handleError(msg, duration) {
    message.error(msg, duration)
  }

  render() {
    let { sortedInfo, filteredInfo } = this.state
    sortedInfo = sortedInfo || {}
    filteredInfo = filteredInfo || {}

    const columnsGlobalSearch = [
      /*DED*/
      {
        title: 'DED',
        key: 'ded',
        width: '12%',
        dataIndex: 'ded',
        filters: this.state.dedNumberFilterList,
        filteredValue: filteredInfo.ded || null,
        onFilter: (value, record) =>
          record.ded.toString().includes(value),
        sorter: (a, b) => a.ded - b.ded,
        sortOrder: sortedInfo.columnKey === 'ded' && sortedInfo.order,
        ellipsis: true,
      },
      /*Coupon Name E*/
      {
        title: 'Coupon Name E',
        dataIndex: 'couponNameEn',
        key: 'couponNameEn',
        width: '12%',
        sorter: (a, b) =>
          a.couponNameEn.toString().localeCompare(b.couponNameEn),
        sortOrder:
          sortedInfo.columnKey === 'couponNameEn' && sortedInfo.order,
        ellipsis: true,
      },
      /*Coupon Name F*/
      {
        title: 'Coupon Name F',
        dataIndex: 'couponNameFr',
        key: 'couponNameFr',
        width: '12%',
        sorter: (a, b) =>
          a.couponNameFr.toString().localeCompare(b.couponNameFr),
        sortOrder:
          sortedInfo.columnKey === 'couponNameFr' && sortedInfo.order,
        ellipsis: true,
      },
      /*UPC Code*/
      {
        title: 'UPC Code',
        dataIndex: 'upc',
        key: 'upc',
        width: '12%',
        sorter: (a, b) => a.upc - b.upc,
        sortOrder: sortedInfo.columnKey === 'upc' && sortedInfo.order,
        ellipsis: true,
      },
      /*PLU Code*/
      {
        title: 'PLU Code',
        dataIndex: 'plu',
        key: 'plu',
        width: '11%',
        sorter: (a, b) => a.plu - b.plu,
        sortOrder: sortedInfo.columnKey === 'plu' && sortedInfo.order,
        ellipsis: true,
      },
      /*Status*/
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '13%',
        filters: this.state.couponStatusFilterList,
        filteredValue: filteredInfo.status || null,
        onFilter: (value, record) => record.status.includes(value),
        sorter: (a, b) => a.status.toString().localeCompare(b.status),
        sortOrder:
          sortedInfo.columnKey === 'status' && sortedInfo.order,
        ellipsis: true,
      },
    ]

    const columnsDataResultAllCouponsByDed = [
      /*DED*/
      {
        title: 'DED',
        key: 'ded',
        width: '6%',
        dataIndex: 'ded',
        filters: this.state.dedNumberFilterList,
        filteredValue: filteredInfo.ded || null,
        onFilter: (value, record) =>
          record.ded.toString().includes(value),
        sorter: (a, b) => a.ded - b.ded,
        sortOrder: sortedInfo.columnKey === 'ded' && sortedInfo.order,
        ellipsis: true,
      },
      /*Coupon Name E*/
      {
        title: 'Coupon Name',
        dataIndex: 'couponNameEn',
        key: 'couponNameEn',
        width: '30%',
        sorter: (a, b) =>
          a.couponNameEn.toString().localeCompare(b.couponNameEn),
        sortOrder:
          sortedInfo.columnKey === 'couponNameEn' && sortedInfo.order,
        ellipsis: true,
        render: (text, record) => {
          return (
            <Button
              type="link"
              onClick={() =>
                this.handleEditDocument(record, 'editCoupon')
              }
            >
              {text}
            </Button>
          )
        },
      },
      /*UPC Code*/
      {
        title: 'UPC Code',
        dataIndex: 'upc',
        key: 'upc',
        width: '8%',
        sorter: (a, b) => a.upc - b.upc,
        sortOrder: sortedInfo.columnKey === 'upc' && sortedInfo.order,
        ellipsis: true,
      },
      /*PLU Code*/
      {
        title: 'PLU Code',
        dataIndex: 'plu',
        key: 'plu',
        width: '8%',
        sorter: (a, b) => a.plu - b.plu,
        sortOrder: sortedInfo.columnKey === 'plu' && sortedInfo.order,
        ellipsis: true,
      },
      /*Category*/
      {
        title: 'Category',
        dataIndex: 'couponCategory',
        key: 'couponCategory',
        width: '8%',
        filters: this.state.categoryFilterList,
        filteredValue: filteredInfo.couponCategory || null,
        onFilter: (value, record) =>
          record.couponCategory.toString().includes(value),
        sorter: (a, b) =>
          a.couponCategory.toString().localeCompare(b.couponCategory),
        sortOrder:
          sortedInfo.columnKey === 'couponCategory' &&
          sortedInfo.order,
        ellipsis: true,
      },
      /*Status*/
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '7%',
        filters: this.state.couponStatusFilterList,
        filteredValue: filteredInfo.status || null,
        onFilter: (value, record) => record.status.includes(value),
        sorter: (a, b) => a.status.toString().localeCompare(b.status),
        sortOrder:
          sortedInfo.columnKey === 'status' && sortedInfo.order,
        ellipsis: true,
      },
    ]

    const columnsDataResultAllCoupons = [
      {
        title: 'Coupon Name E',
        key: 'couponNameEn',
        width: '22%',
        dataIndex: 'couponNameEn',
        sorter: (a, b) => {
          return a.couponNameEn.localeCompare(b.couponNameEn)
        },
        sortOrder:
          sortedInfo.columnKey === 'couponNameEn' && sortedInfo.order,
        ellipsis: true,
        render: (text, record) => {
          return (
            <Button
              type="link"
              onClick={() =>
                this.handleEditDocument(record, 'editCoupon')
              }
            >
              {text}
            </Button>
          )
        },
      },
      {
        title: 'Coupon Name F',
        dataIndex: 'couponNameFr',
        key: 'couponNameFr',
        width: '22%',
        sorter: (a, b) =>
          a.couponNameFr.toString().localeCompare(b.couponNameFr),
        sortOrder:
          sortedInfo.columnKey === 'couponNameFr' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: 'Till Name EN',
        dataIndex: 'tillNameEn',
        key: 'tillNameEn',
        width: '15%',
        sorter: (a, b) =>
          a.tillNameEn.toString().localeCompare(b.tillNameEn),
        sortOrder:
          sortedInfo.columnKey === 'tillNameEn' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: 'Till Name FR',
        dataIndex: 'tillNameFr',
        key: 'tillNameFr',
        width: '15%',
        sorter: (a, b) =>
          a.tillNameFr.toString().localeCompare(b.tillNameFr),
        sortOrder:
          sortedInfo.columnKey === 'tillNameFr' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: 'PLU Code',
        dataIndex: 'plu',
        key: 'plu',
        width: '10%',
        sorter: (a, b) => a.plu - b.plu,
        sortOrder: sortedInfo.columnKey === 'plu' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '8%',
        filters: this.state.couponStatusFilterList,
        filteredValue: filteredInfo.status || null,
        onFilter: (value, record) => record.status.includes(value),
        sorter: (a, b) => a.status.toString().localeCompare(b.status),
        sortOrder:
          sortedInfo.columnKey === 'status' && sortedInfo.order,
        ellipsis: true,
      },
    ]

    const columnsDataResultByDedNumber = [
      {
        title: 'DED',
        key: 'ded',
        width: '12%',
        dataIndex: 'ded',
        filters: this.state.dedNumberFilterList,
        filteredValue: filteredInfo.ded || null,
        onFilter: (value, record) =>
          record.ded.toString().includes(value),
        sorter: (a, b) => a.ded - b.ded,
        sortOrder: sortedInfo.columnKey === 'ded' && sortedInfo.order,
        ellipsis: true,
        render: (text, record) => {
          return (
            <Button
              type="link"
              onClick={() =>
                this.handleEditDocument(record, 'editDed')
              }
            >
              {text}
            </Button>
          )
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '12%',
        sorter: (a, b) =>
          a.description.toString().localeCompare(b.description),
        sortOrder:
          sortedInfo.columnKey === 'description' && sortedInfo.order,
        ellipsis: true,
      },
    ]

    return (
      <div>
        <div
          style={{
            width: 450,
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
          }}
        >
          <a href="https://www.aw.ca">
            <Icon
              component={() => <img src={logo} alt="A&amp;W Logo" />}
            />
          </a>
        </div>
        <div
          className="container p-3 my-3 border shadow p-3 mb-3 rounded"
          style={{
            background: '#fff',
            border: 'rounded',
            maxWidth: '80%',
          }}
        >
          <h2 className="ant-col-title">Coupon File - List</h2>
          <div
            className="jumbotron"
            style={{
              background: '#fff',
              paddingLeft: '15px',
              paddingTop: '10px',
              paddingBottom: '0px',
            }}
          >
            <Menu
              onClick={(e) => this.handleOnChangeCoupon(e.key)}
              mode="horizontal"
              style={{ color: '#ff6319', borderColor: '#ff6319' }}
            >
              <Menu.Item
                key="0"
                title="Click Here To List All Coupons by DED"
              >
                All Coupons by DED
              </Menu.Item>
              <Menu.Item
                key="1"
                title="Click Here To List All Coupons with Till Name"
              >
                All Coupons with Till Name
              </Menu.Item>
              <Menu.Item
                key="2"
                title="Click Here To List All DED Numbers"
              >
                DED Numbers
              </Menu.Item>
            </Menu>
            {this.state.showCouponFile ? (
              <div>
                &nbsp;&nbsp;&nbsp;
                <div className="col-">
                  <AutoComplete
                    dropdownClassName="certain-category-search-dropdown"
                    style={{ width: '100%', paddingBottom: '10px' }}
                    onChange={debounce(this.handleGlobalSearch, 250)}
                    allowClear={true}
                  >
                    <Input.Search
                      size="default"
                      placeholder="Search Coupon"
                      onPressEnter={() => {}}
                    />
                  </AutoComplete>
                </div>
                <Row gutter={{ xs: 2, sm: 2, md: 3, lg: 3 }}>
                  &nbsp;&nbsp;&nbsp;
                  <Col className="gutter-row" span={3}>
                    <Button
                      size="small"
                      shape="round"
                      style={{
                        display: this.state.createIconVisible
                          ? 'block'
                          : 'none',
                        borderColor: '#ff6319',
                        color: '#ff6319',
                      }}
                      onClick={() => this.handleCreateDocument()}
                      title="Create a New Coupon"
                    >
                      {' '}
                      + New Coupon
                    </Button>
                  </Col>
                  &nbsp;&nbsp;&nbsp;
                  <Col className="gutter-row" span={3}>
                    <Button
                      size="small"
                      shape="round"
                      style={{
                        display: this.state.createIconVisible
                          ? 'block'
                          : 'none',
                        borderColor: '#ff6319',
                        color: '#ff6319',
                      }}
                      onClick={() => this.handleCreateDed()}
                      title="Create a New Ded"
                    >
                      {' '}
                      + New DED
                    </Button>
                  </Col>
                  &nbsp;&nbsp;&nbsp;
                  <Col className="gutter-row" span={3}>
                    <Button
                      size="small"
                      shape="round"
                      style={{
                        display: this.state.createIconVisible
                          ? 'block'
                          : 'none',
                        borderColor: '#ff6319',
                        color: '#ff6319',
                      }}
                      onClick={() => this.clearFilters()}
                      title="Clear Filters"
                    >
                      {' '}
                      Clear Filters
                    </Button>
                  </Col>
                  &nbsp;&nbsp;&nbsp;
                  <Col
                    className="gutter-row"
                    span={3}
                    style={{ paddingLeft: '40%' }}
                  >
                    <Popconfirm
                      title="Are you sure you want to Publish Upcoming Coupons To QSR？"
                      onConfirm={() => this.handleOnChangeCoupon(4)}
                      icon={
                        <QuestionCircleOutlined
                          style={{ color: 'red' }}
                        />
                      }
                    >
                      <Button
                        size="small"
                        style={{
                          display: this.state.createIconVisible
                            ? 'block'
                            : 'none',
                          borderColor: '#ff6319',
                          color: '#ff6319',
                        }}
                        // onClick={() => this.handleOnChangeCoupon(4)}
                        title="Publish Upcoming Coupons To QSR"
                      >
                        {' '}
                        Publish Upcoming Coupons To QSR
                      </Button>
                    </Popconfirm>
                  </Col>
                  <br></br>
                  <br></br>
                </Row>
              </div>
            ) : null}

            {this.state.showCouponFile ? (
              <div>
                {this.state.showMyTable ? (
                  <Table
                    style={{
                      display: '',
                      width: '100%',
                      paddingBottom: '10px',
                    }}
                    rowKey="_id"
                    pagination={false}
                    size="small"
                    columns={columnsGlobalSearch}
                    dataSource={this.state.items}
                    onRow={(record) => ({
                      onClick: () => {
                        this.handleOnClickDet(record)
                      },
                    })}
                  />
                ) : null}

                {this.state.showMyTable2 ? (
                  <Table
                    rowKey="_id"
                    pagination={{
                      total: this.state.recordNumber,
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                      pageSize: 30,
                      style: {
                        width: '100%',
                        paddingBottom: '20px',
                        textAlign: 'right',
                      },
                    }}
                    size="small"
                    columns={columnsDataResultAllCouponsByDed}
                    dataSource={this.state.items}
                    number={0}
                    onRow={(record) => ({
                      onClick: () => {
                        this.handleOnClickDet(record)
                      },
                    })}
                    onFilter={(filteredDataSource, activeFilters) => {
                      this.setState({
                        numberOfRowsInTable:
                          filteredDataSource.length,
                      })
                    }}
                    onChange={this.handleChange}
                  />
                ) : null}

                {this.state.showMyTable3 ? (
                  <Table
                    rowKey="_id"
                    pagination={{
                      total: this.state.recordNumber,
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                      pageSize: 30,
                      style: {
                        width: '100%',
                        paddingBottom: '20px',
                        textAlign: 'right',
                      },
                    }}
                    size="small"
                    columns={columnsDataResultAllCoupons}
                    dataSource={this.state.items}
                    number={0}
                    onRow={(record) => ({
                      onClick: () => {
                        this.handleOnClickDet(record)
                      },
                    })}
                    onFilter={(filteredDataSource, activeFilters) => {
                      this.setState({
                        numberOfRowsInTable:
                          filteredDataSource.length,
                      })
                    }}
                    onChange={this.handleChange}
                  />
                ) : null}

                {this.state.showMyTable4 ? (
                  <Table
                    rowKey="_id"
                    pagination={{
                      total: this.state.recordNumber,
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                      pageSize: 30,
                      style: {
                        width: '100%',
                        paddingBottom: '20px',
                        textAlign: 'right',
                      },
                    }}
                    size="small"
                    columns={columnsDataResultByDedNumber}
                    dataSource={this.state.items}
                    number={0}
                    onRow={(record) => ({
                      onClick: () => {
                        this.handleOnClickDet(record)
                      },
                    })}
                    onFilter={(filteredDataSource, activeFilters) => {
                      this.setState({
                        numberOfRowsInTable:
                          filteredDataSource.length,
                      })
                    }}
                    onChange={this.handleChange}
                  />
                ) : null}
              </div>
            ) : null}
            {this.state.showPromoCode ? (
              <div>
                <PromoCodeDashboardContainer />
              </div>
            ) : null}
          </div>
          <footer style={{ textAlign: 'center', color: '#ff6319' }}>
            {process.env.REACT_APP_NAME} -{' '}
            {process.env.REACT_APP_VERSION}
          </footer>
        </div>
      </div>
    )
  }
}

export default CouponFileDashboard
