import React, { useState, useCallback } from 'react'
import '../core/resources/style.css'
import Axios from 'agilite-utils/axios'
import moment from 'moment'

import CouponFileDashboardContainer from './couponFileDashboard-container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UploadOutlined } from '@ant-design/icons'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import {
  Icon,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Table,
  Spin,
  Card,
  message,
  Alert,
} from 'antd'

import { useDispatch } from 'react-redux'

import { useDropzone } from 'react-dropzone'

import MemoryStore from './resources/memory-store'
import theme from './resources/theme'

import CoreEnums from '../core/resources/enums'

import logo from './resources/aw_logo.png'

import './resources/style.css'

const FormItem = Form.Item
const Option = Select.Option

const CouponFile = () => {
  const dispatch = useDispatch()
  const entry = MemoryStore.couponForm
  const dedNumberFilterList = []
  const marketRegionFilterList = []
  const dedNumberAndDescription = []

  const [signInClicked, setSignInClicked] = useState(false)
  const [regionDisabled, setRegionDisabled] = useState(false)
  const [dedDropDownList, setItems] = useState([])
  const [marketRegionDropDownList, setItemsMarketRegion] = useState(
    [],
  )
  const [countCharacter, setCountCharacter] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [countMaxChar, setCountMaxChar] = useState(27)
  const [upcNumber, setUpcNumber] = useState(0)
  const [loading, setLoading] = useState(false)
  const [fileUploads, setFileUploads] = useState(
    entry.fileAttachments ? entry.fileAttachments : [],
  )
  const [statusAction, setStatusAction] = useState(
    entry.isNewCoupon === false ? 0 : 1,
  )
  // eslint-disable-next-line no-unused-vars
  const [
    dedNumberAndDescriptionList,
    setItemsDedNumberAndDescriptionList,
  ] = useState([]) // Value is set later but never used anywhere
  // eslint-disable-next-line no-unused-vars
  const [previousStatus, setNewStatus] = useState(
    entry.status !== ''
      ? (entry.previousStatus = entry.status)
      : entry.status,
  )
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState(
    entry.status === '' ? 'Draft' : entry.status,
  )
  const [requiredField, setrequiredField] = useState(false)
  const [
    requiredFieldPlEnglish,
    setrequiredFieldPlEnglish,
  ] = useState(true)
  const [requiredFieldPlFrench, setrequiredFieldPlFrench] = useState(
    true,
  )
  const [pluNumber, setPluNumber] = useState(0)
  const [pluDisabledValidation, setPluDisabledValidation] = useState(
    false,
  )

  const [showMSGCouponNameE, setshowMSGCouponNameE] = useState(false)
  const [showMSGCouponNameF, setShowMSGCouponNameF] = useState(false)
  const [showMSGTillE, setShowMSGTillE] = useState(false)
  const [showMSGTillF, setShowMSGTillF] = useState(false)
  const [showMSGDED, setShowMSGDED] = useState(false)
  const [showMSGUPC, setShowMSGUPC] = useState(false)
  const [showMSGCouponStatus, setShowMSGCouponStatus] = useState(
    false,
  )
  const [showMSGPLU, setShowMSGPLU] = useState(false)
  const [showMSGEffectiveDate, setShowMSGEffectiveDate] = useState(
    false,
  )
  const [showMSGTillInstE, setShowMSGTillInstE] = useState(false)
  const [showMSGTillInstF, setShowMSGTillInstF] = useState(false)
  const [showMSGBarcode, setShowMSGBarcode] = useState(false)
  const [showMSGCategory, setShowMSGCategory] = useState(false)
  const [showMSGCoverage, setShowMSGCoverage] = useState(false)

  // Function that gets called when files are added through dropzone
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Show loading icon
      setLoading(true)

      acceptedFiles.forEach((file) => {
        // Instantiate a new instance of the built in FileReader
        // eslint-disable-next-line
      const reader = new FileReader()

        reader.onabort = () =>
          message.warn('File reading was aborted')
        reader.onerror = () => message.error('Unable to read file')

        // onload gets called when FileReader successfully readed a file
        reader.onload = () => {
          // Set the fileData state to contain the new file that was read
          const newFile = {
            fileName: file.name,
            fileData: JSON.stringify(Buffer.from(reader.result)),
            filePath: file.path,
            fileSize: file.size,
            key: Date.now(),
          }
          setFileUploads((previousData) => [...previousData, newFile])

          // Set the fileId to the key value so we can identify which fileId belongs to which record after uploading
          entry.fileAttachments.push({
            fileName: file.name,
            key: newFile.key,
            type: file.type,
          })
          setLoading(false)
        }

        // Read the file as an array buffer, 'onload' function will be called once completed
        reader.readAsArrayBuffer(file)
      })
    },
    [entry.fileAttachments],
  )

  // Instantiate dropzone component
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
  })

  const removeFile = (fileKey) => () => {
    const newFiles = [...fileUploads]
    for (let i = 0; i < newFiles.length; i++) {
      if (newFiles[i].key === fileKey) {
        newFiles.splice(i, 1)
        setFileUploads(newFiles)

        for (i = 0; i < entry.fileAttachments.length; i++) {
          if (entry.fileAttachments[i].key === fileKey) {
            entry.fileAttachments.splice(i, 1)
          }
        }
        return
      }
    }
  }

  React.useEffect(() => {
    if (entry.isNewCoupon) {
      handleRequiredFields(status)
    } else {
      loadDescriptionDed()
      handleRequiredFields()
      handleProgrammingLanguage()
      handleRequiredFields(status)
    }

    loadListDedNumber()
    loadListMarketRegion()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadDescriptionDed = async () => {
    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      data: {
        qry: { ded: { $eq: entry.ded } },
        qryOptions: '',
        fieldsToReturn: '',
        page: null,
        pageLimit: null,
      },
      headers: {
        'Content-Type': 'application/json',
        email: MemoryStore.userProfile.profile.login,
        type: 'coupon',
        action: 'get',
      },
    }).then((res) => {
      dedNumberAndDescription.push({
        key: res.data[0].ded,
        label: res.data[0].description,
        value: res.data[0].ded,
      })

      setItemsDedNumberAndDescriptionList(
        dedNumberAndDescription.map(({ key, label, value }) => ({
          key: key,
          label: label,
          value: value,
        })),
      )
      entry.ded = dedNumberAndDescription[0].key
      entry.dedName = dedNumberAndDescription[0].label

      entry.dedNumberString =
        entry.ded.toString() + ' - ' + entry.dedName
    })
  }

  const loadListDedNumber = async () => {
    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      data: {
        qry: '',
        qryOptions: '',
        fieldsToReturn: '',
        page: null,
        pageLimit: null,
      },
      headers: {
        'Content-Type': 'application/json',
        email: MemoryStore.userProfile.profile.login,
        type: 'ded',
        action: 'get',
      },
    }).then((res) => {
      const dataPayload = res.data
      const dupCheck = {}
      let dataEntry = null

      for (const x in dataPayload) {
        dataEntry = dataPayload[x]
        if (!dupCheck[dataEntry.ded]) {
          dupCheck[dataEntry.ded] = dataEntry.ded
          dedNumberFilterList.push({
            key: dataEntry.ded,
            label: dataEntry.description,
            value: dataEntry.ded,
          })
        }
      }

      setItems(
        dedNumberFilterList.map(({ key, label, value }) => ({
          key: key,
          label: label,
          value: value,
        })),
      )
    })
  }

  const loadListMarketRegion = async () => {
    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      data: {
        qry: '',
        qryOptions: '',
        fieldsToReturn: '',
        page: null,
        pageLimit: null,
      },
      headers: {
        'Content-Type': 'application/json',
        email: MemoryStore.userProfile.profile.login,
        type: 'mktRegion',
        action: 'get',
      },
    }).then((res) => {
      const dataPayload = res.data
      const dupCheck = {}
      let dataEntry = null

      for (const x in dataPayload) {
        dataEntry = dataPayload[x]
        if (!dupCheck[dataEntry.MKT_REGION_ID]) {
          dupCheck[dataEntry.MKT_REGION_ID] = dataEntry.MKT_REGION_ID
          marketRegionFilterList.push({
            key: dataEntry.MKT_REGION_ID,
            label: dataEntry.NAME,
            value: dataEntry.NAME,
          })
        }
      }
      setItemsMarketRegion(
        marketRegionFilterList.map(({ key, label, value }) => ({
          key: key,
          label: label,
          value: value,
        })),
      )
    })
  }

  const loadBarcodeImage = async (fileId) => {
    let file
    for (file of entry.fileAttachments) {
      if (file.fileId === fileId) {
        break
      }
    }

    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      data: {
        fileId: fileId,
      },
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        id: fileId,
        type: 'upload',
        action: 'get',
      },
    })
      .then((res) => {
        const blob = new Blob([res.data])
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = file.fileName
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
      })
      .catch((err) => {
        console.log('Error - ', err)
      })
  }

  const handleCouponFile = (e, isNewCoupon) => {
    // Prevent default form postback behaviour from reloading the page
    console.log('handleCouonFile')
    if (e.preventDefault) {
      e.preventDefault()
    }

    if (handleValidation()) {
      setLoading(true)
      if (isNewCoupon) {
        setStatusAction(1)
      } else {
        setStatusAction(0)
      }

      if (
        (Array.isArray(fileUploads) && fileUploads.length) ||
        entry.status === 'Draft' ||
        entry.status === 'Eliminated'
      ) {
        handleUploadFile(e)
      } else {
        warningTime('Warning - Please upload a barcode image', 3)
      }
      setLoading(false)
    }
  }
  const handleUploadFile = (e) => {
    if (Array.isArray(fileUploads) && fileUploads.length) {
      const uploads = []
      let uploadCount = 0
      for (let i = 0; i < fileUploads.length; i++) {
        if (fileUploads[i].fileData) {
          uploadCount++
          uploads[i] = Axios({
            method: 'post',
            url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
            data: {
              filesData: [fileUploads[i]],
            },
            headers: {
              'Content-Type': 'application/json',
              id: entry.fileAttachments[i].fileId,
              type: 'upload',
              action: 'update',
            },
          })
        }
      }

      // Wait for all concurrent uploads to finish
      Promise.all(uploads)
        .then((res) => {
          // Update all the file IDs
          for (let i = 0; i < res.length; i++) {
            if (res[i]) {
              entry.fileAttachments[i].fileId =
                res[i].data.filesData._id
            }
          }

          if (uploadCount > 0) {
            success('Success - Attachments have been uploaded')
          }

          if (statusAction === 0) {
            handleUpdateCouponFile(e)
          }
          if (statusAction === 1) {
            handleCreateCouponFile(e)
          }
        })
        .catch((err) => {
          error('Error - Attachments were not uploaded')
          console.error(err)
          setSignInClicked(false)
        })
    } else {
      // if the barcode is not uploaded
      if (statusAction === 0) {
        handleUpdateCouponFile(e)
      }
      if (statusAction === 1) {
        handleCreateCouponFile(e)
      }
    }
  }

  const handleCreateCouponFile = (e) => {
    if (e instanceof Event) {
      e.preventDefault()
    }
    setSignInClicked(true)
    setRegionDisabled(true)
    setPluDisabledValidation(true)
    setRegionDisabled(true)
    entry.couponCreated = moment().format('YYYY-MM-DD HH:mm')

    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      data: {
        couponNameEn: entry.couponNameEn,
        couponNameFr: entry.couponNameFr,
        tillNameEn: entry.tillNameEn,
        tillNameFr: entry.tillNameFr,
        ded: entry.ded,
        upc: entry.upc,
        plu: entry.plu,
        couponCreated: entry.couponCreated,
        couponCategory: entry.couponCategory,
        couponCoverage: entry.couponCoverage,
        startDate: entry.startDate,
        endDate: entry.endDate,
        tillInstructionsE: entry.tillInstructionsE,
        tillInstructionsF: entry.tillInstructionsF,
        region: entry.region,
        criteria: entry.criteria,
        language: entry.language,
        fileAttachments: entry.fileAttachments,
        status: entry.status,
        globalSearch: '',
        updatedDate: entry.updatedDate,
        updatedBy: entry.updatedBy,
      },
      headers: {
        'Content-Type': 'application/json',
        type: 'coupon',
        action: 'insert',
      },
    })
      .then((res) => {
        success('Success - The Coupon File has been created')
        if (
          entry.sendNotification === '1' ||
          entry.sendNotification === ''
        ) {
          handleSendNotification('create', entry.status)
        } else {
          handleHomePage(1)
        }
      })
      .catch((err) => {
        error('Error - The Coupon File has not been created')
        console.error(err)
        setSignInClicked(false)
      })
  }

  const handleValidation = (e) => {
    console.log('handlevalidation: ' + entry.status)
    console.log('handlevalidation: ' + entry.language)
    var ret = true
    if (
      entry.language !== 'French' &&
      entry.couponNameEn === '' &&
      entry.status === 'Draft'
    ) {
      setshowMSGCouponNameE(true)
      ret = false
    } else {
      setshowMSGCouponNameE(false)
    }

    if (
      entry.couponNameFr === '' &&
      entry.language !== 'English' &&
      entry.status === 'Draft'
    ) {
      setShowMSGCouponNameF(true)
      ret = false
    } else {
      setShowMSGCouponNameF(false)
    }

    if (
      entry.tillNameEn === '' &&
      entry.language !== 'French' &&
      entry.status === 'Upcoming'
    ) {
      setShowMSGTillE(true)
      ret = false
    } else {
      setShowMSGTillE(false)
    }

    if (
      entry.tillNameFr === '' &&
      entry.language !== 'English' &&
      entry.status === 'Upcoming'
    ) {
      setShowMSGTillF(true)
      ret = false
    } else {
      setShowMSGTillF(false)
    }

    if (entry.status === '') {
      setShowMSGCouponStatus(true)
      ret = false
    } else {
      setShowMSGCouponStatus(false)
    }

    if (entry.ded === '' && entry.status === 'Draft') {
      setShowMSGDED(true)
      ret = false
    } else {
      setShowMSGDED(false)
    }

    if (entry.plu === '' && entry.status === 'Draft') {
      setShowMSGPLU(true)
      ret = false
    } else {
      setShowMSGPLU(false)
    }

    if (entry.upc === '') {
      setShowMSGUPC(true)
      ret = false
    } else {
      setShowMSGUPC(false)
    }

    if (entry.startDate === '' && entry.status === 'Draft') {
      setShowMSGEffectiveDate(true)
      ret = false
    } else {
      setShowMSGEffectiveDate(false)
    }

    if (
      entry.tillInstructionsE === '' &&
      entry.language !== 'French' &&
      entry.status === 'Upcoming'
    ) {
      setShowMSGTillInstE(true)
      ret = false
    } else {
      setShowMSGTillInstE(false)
    }

    if (
      entry.tillInstructionsF === '' &&
      entry.language !== 'English' &&
      entry.status === 'Upcoming'
    ) {
      setShowMSGTillInstF(true)
      ret = false
    } else {
      setShowMSGTillInstF(false)
    }

    if (entry.fileAttachments === '') {
      setShowMSGBarcode(true)
      ret = false
    } else {
      setShowMSGBarcode(false)
    }

    if (entry.couponCategory === '' && entry.status === 'Draft') {
      setShowMSGCategory(true)
      ret = false
    } else {
      setShowMSGCategory(false)
    }

    if (entry.couponCoverage === '' && entry.status === 'Draft') {
      setShowMSGCoverage(true)
      ret = false
    } else {
      setShowMSGCoverage(false)
    }

    return ret
  }

  const handleUpdateCouponFile = (e) => {
    if (e instanceof Event) {
      e.preventDefault()
    }
    setSignInClicked(true)
    setRegionDisabled(true)

    entry.updatedDate = moment().format('YYYY-MM-DD HH:mm')
    const user = MemoryStore.userProfile.profile.login
    entry.updatedBy = user.substring(0, user.lastIndexOf('@'))

    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      data: {
        couponNameEn: entry.couponNameEn,
        couponNameFr: entry.couponNameFr,
        tillNameEn: entry.tillNameEn,
        tillNameFr: entry.tillNameFr,
        ded: entry.ded,
        upc: entry.upc,
        plu: entry.plu,
        couponCreated: entry.couponCreated,
        couponCategory: entry.couponCategory,
        couponCoverage: entry.couponCoverage,
        startDate: entry.startDate,
        endDate: entry.endDate,
        tillInstructionsE: entry.tillInstructionsE,
        tillInstructionsF: entry.tillInstructionsF,
        region: entry.region,
        criteria: entry.criteria,
        language: entry.language,
        fileAttachments: entry.fileAttachments,
        status: entry.status,
        globalSearch: '',
        updatedDate: entry.updatedDate,
        updatedBy: entry.updatedBy,
      },
      headers: {
        'Content-Type': 'application/json',
        email: MemoryStore.userProfile.profile.login,
        id: entry.couponId,
        type: 'coupon',
        action: 'update',
      },
    })
      .then((res) => {
        success('Success - The Coupon File has been updated')
        if (entry.sendNotification === '1') {
          handleSendNotification('update', entry.status)
        } else {
          handleHomePage(2)
        }
      })
      .catch((err) => {
        error('Error - The The Coupon File has not been updated')
        console.error(err)
        setSignInClicked(false)
      })
  }

  const handleSendNotification = (value, couponStatus) => {
    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      data: {
        couponNameEn: entry.couponNameEn,
        couponNameFr: entry.couponNameFr,
        tillNameEn: entry.tillNameEn,
        tillNameFr: entry.tillNameFr,
        ded: entry.ded,
        plu: entry.plu,
      },
      headers: {
        'Content-Type': 'application/json',
        email: MemoryStore.userProfile.profile.login,
        id: entry.couponId,
        type: 'notification',
        action: value,
        previousstatus: previousStatus,
        couponstatus: couponStatus,
      },
    })
      .then((res) => {
        success('Success - The Notification has been sent')
        if (value === 'create') {
          handleHomePage(1)
        } else {
          handleHomePage(2)
        }
      })
      .catch((err) => {
        error('Error -The Notification has not been sent')
        console.error(err)
        // Coupon file and attachments were successful, but email failed to send
        // Sending the user back to the home page anyway because re-saving the entire file doesn't do much to help us
        // and may just create duplicate data
        // TODO: Is this how we want to manage this scenario?
        handleHomePage(-1)
      })
  }

  const handleHomePage = (value) => {
    // Clear loaded coupon state on redirect home
    // TODO: Modify memorystore to a factory pattern or similar
    //       so we deal with instances of a model instead of global changes
    entry.couponId = ''
    entry.couponNameEn = ''
    entry.couponNameFr = ''
    entry.tillNameEn = ''
    entry.tillNameFr = ''
    entry.isNewCoupon = false
    entry.ded = ''
    entry.upc = ''
    entry.plu = ''
    entry.couponCreated = ''
    entry.couponCategory = ''
    entry.couponCoverage = ''
    entry.startDate = ''
    entry.endDate = ''
    entry.tillInstructionsE = ''
    entry.tillInstructionsF = ''
    entry.region = ''
    entry.criteria = ''
    entry.language = ''
    entry.fileId = ''
    entry.fileAttachments = []
    entry.fileName = ''
    entry.status = ''
    entry.globalSearch = ''
    entry.updatedDate = ''
    entry.updatedBy = ''

    dispatch({
      type: CoreEnums.reducers.LOAD_CONTENT,
      payload: {
        content: <CouponFileDashboardContainer />,
        title: 'Coupon File Dashboard',
        key: 'couponFileDashboard',
        app: 'couponFileDashboard',
        closable: false,
        custom: {},
      },
    })
  }

  const handleValidatePluDuplicate = (e) => {
    e.preventDefault()
    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      data: {
        qry: { plu: { $eq: entry.plu } },
        qryOptions: '',
        fieldsToReturn: '',
        page: null,
        pageLimit: null,
      },
      headers: {
        'Content-Type': 'application/json',
        type: 'coupon',
        action: 'get',
      },
    })
      .then((res) => {
        if (res.data.length > 0) {
          for (const x in res.data) {
            if (parseInt(entry.plu) === res.data[x].plu) {
              setPluNumber(12)
              warningTime('Warning - The PLU already exists', 3)
              setSignInClicked(true)
              setSignInClicked(true)
              setRegionDisabled(true)
            } else {
              setPluNumber(parseInt(entry.plu))
              setSignInClicked(false)
              setRegionDisabled(false)
            }
          }
        } else {
          setPluNumber(parseInt(entry.plu))
          setSignInClicked(false)
          setRegionDisabled(false)
        }
      })
      .catch((err) => {
        errorTime('Error - Cheking duplicated PLUs', 3)
      })
  }

  const handleWordCount = (value) => {
    const charCount = value.length
    const maxChar = countMaxChar
    const charLength = maxChar - charCount
    setCountCharacter(charLength)
  }

  const handleGenerateUpc = (value) => {
    if (value.length === 6) {
      const dedNumber = entry.ded
      const doubleZero = '00'
      const pluNumber = value
      const concatenateUpcNumber = dedNumber + doubleZero + pluNumber
      setUpcNumber(parseInt(concatenateUpcNumber))
      entry.upc = parseInt(concatenateUpcNumber)
    } else {
      entry.upc = ''
      setUpcNumber('')
    }
  }

  const handleRequiredFields = (value) => {
    entry.status = value

    if (entry.status === 'Draft') {
      setrequiredField(false)
      setrequiredFieldPlEnglish(false)
      setrequiredFieldPlFrench(false)
    } else {
      setrequiredField(true)
      setrequiredFieldPlEnglish(true)
      setrequiredFieldPlFrench(true)
    }
  }

  const handleProgrammingLanguage = (value) => {
    entry.language = value

    if (entry.language === 'English') {
      setrequiredFieldPlEnglish(true)
      setrequiredFieldPlFrench(false)
    } else if (entry.language === 'French') {
      setrequiredFieldPlEnglish(false)
      setrequiredFieldPlFrench(true)
    } else if (entry.language === 'Both') {
      setrequiredFieldPlEnglish(true)
      setrequiredFieldPlFrench(true)
    }
  }

  const handleCoverage = (value) => {
    if (value === 'National') {
      setRegionDisabled(true)
    } else {
      entry.region = ''
      setRegionDisabled(false)
    }
  }

  const onChange = (key, value) => {
    switch (key) {
      case 'inputCouponNameEn':
        entry.couponNameEn = value
        break
      case 'inputCouponNameFr':
        entry.couponNameFr = value
        break
      case 'inputTillNameEn':
        entry.tillNameEn = value
        handleWordCount(value)
        break
      case 'inputTillNameFr':
        entry.tillNameFr = value
        handleWordCount(value)
        break
      case 'inputDed':
        entry.ded = value
        break
      case 'inputPlu':
        entry.plu = value
        handleGenerateUpc(value)
        break
      case 'inputCouponCreated':
        entry.couponCreated = value
        break
      case 'inputCouponCategory':
        entry.couponCategory = value
        break
      case 'inputCouponCoverage':
        handleCoverage(value)
        entry.couponCoverage = value
        break
      case 'inputStartDate':
        entry.startDate = moment(value).format('YYYY-MM-DD HH:mm')
        break
      case 'inputEndDate':
        entry.endDate = moment(value).format('YYYY-MM-DD HH:mm')
        break
      case 'inputTillInstructionsE':
        entry.tillInstructionsE = value
        break
      case 'inputTillInstructionsF':
        entry.tillInstructionsF = value
        break
      case 'inputRegion':
        entry.region = value
        break
      case 'inputCriteria':
        entry.criteria = value
        break
      case 'inputLanguage':
        handleProgrammingLanguage(value)
        entry.language = value
        break
      case 'inputfileId':
        entry.fileId = value
        break
      case 'inputStatus':
        handleNotification(value)
        // handleRequiredFields(value)
        entry.status = value
        break
      case 'inputUpdatedDate':
        entry.updatedDate = value
        break
      case 'inputUpdatedBy':
        entry.updatedBy = value
        break
      default:
    }
  }

  const handleNotification = (value) => {
    if (previousStatus !== value) {
      if (value === 'Upcoming' || value === 'Active') {
        entry.sendNotification = '0'
      } else if (
        previousStatus === 'Active' &&
        value === 'Eliminated'
      ) {
        entry.sendNotification = '1'
      } else if (
        previousStatus === 'Eliminated' &&
        value === 'Draft'
      ) {
        entry.sendNotification = '1'
      } else if (entry.isNewCoupon) {
        entry.sendNotification = '1'
      } else {
        entry.sendNotification = '0'
      }
    } else {
      entry.sendNotification = '0'
    }
  }

  const success = (msg) => {
    message.success(msg)
  }

  const error = (msg) => {
    message.error(msg)
  }

  const errorTime = (msg, duration) => {
    message.error(msg, duration)
  }

  const warningTime = (msg, duration) => {
    message.warning({
      content: msg,
      style: {
        marginTop: '20vh',
      },
      duration: duration,
    })
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 6 },
  }

  return (
    <div>
      <div
        style={{
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block',
        }}
      >
        <a href="https://www.aw.ca">
          <Icon
            component={() => <img src={logo} alt="A&amp;W Logo" />}
          />
        </a>
      </div>
      <div
        className="container p-3 my-3 border shadow p-3 mb-5 rounded"
        style={{ background: '#fff', boarder: 'rounded' }}
      >
        <div style={{ padding: '0px 0px 0px 500px' }}>
          <FontAwesomeIcon
            icon={faHome}
            style={{
              color: '#ff6319',
              height: '30px',
              width: '30px',
              cursor: 'pointer',
            }}
            onClick={() => handleHomePage(0)}
          />
        </div>
        <h2
          style={{ color: '#ff6319', padding: '0px 0px 0px 340px' }}
        >
          Coupon File -{' '}
          {entry.isNewCoupon === false
            ? 'Update Coupon'
            : 'Create Coupon'}
        </h2>
        <Form
          {...layout}
          onSubmit={(e) => {
            handleCouponFile(e, entry.isNewCoupon)
          }}
          className="site-layout-content"
          size="small"
          layout="horizontal"
        >
          {/* line: 1 */}
          <Row gutter={{ xs: 16 }}>
            <Col sm={12}>
              {/* <FormItem label='Coupon Name E' required={requiredFieldPlEnglish}> */}
              <FormItem label="Coupon Name E">
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Input
                    prefix=""
                    type="text"
                    placeholder="Coupon Name in English"
                    defaultValue={entry.couponNameEn}
                    disabled={signInClicked}
                    // required={requiredFieldPlEnglish}
                    size="small"
                    onChange={(e) => {
                      onChange('inputCouponNameEn', e.target.value)
                    }}
                  />
                  {showMSGCouponNameE ? (
                    <Alert
                      message="Please, insert a valid Coupon Name"
                      type="error"
                    />
                  ) : null}
                </div>
              </FormItem>
            </Col>
            <Col sm={12}>
              <FormItem
                label="Coupon Name F:"
                //    required={requiredFieldPlFrench}
              >
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Input
                    prefix=""
                    //    required={requiredFieldPlFrench}
                    placeholder="Coupon Name in French"
                    type="text"
                    size="small"
                    defaultValue={entry.couponNameFr}
                    disabled={signInClicked}
                    onChange={(e) => {
                      onChange('inputCouponNameFr', e.target.value)
                    }}
                  />

                  {showMSGCouponNameF ? (
                    <Alert
                      message="Please, insert a valid Coupon Name"
                      type="error"
                    />
                  ) : null}
                </div>
              </FormItem>
            </Col>
          </Row>
          {/* line: 2 */}
          <Row gutter={{ xs: 16 }}>
            <Col sm={12}>
              <FormItem
                label="Till Name E:"
                //       required={requiredFieldPlEnglish}
              >
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    width: '250px',
                    color: theme.secondary,
                    fontSize: '10px',
                  }}
                >
                  <Input
                    prefix=""
                    // required={requiredFieldPlEnglish}
                    placeholder="Till Name in English"
                    type="text"
                    maxLength={27}
                    size="small"
                    defaultValue={entry.tillNameEn}
                    disabled={signInClicked}
                    onChange={(e) => {
                      onChange('inputTillNameEn', e.target.value)
                    }}
                  />
                  {countCharacter}/{countMaxChar}
                  {showMSGTillE ? (
                    <Alert
                      message="Please, insert a valid Till Name"
                      type="error"
                    />
                  ) : null}
                </div>
              </FormItem>
            </Col>
            <Col sm={12}>
              <FormItem
                label="Till Name F:"
                //    required={requiredFieldPlFrench}
              >
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    width: '250px',
                    color: theme.secondary,
                    fontSize: '10px',
                  }}
                >
                  <Input
                    prefix=""
                    //required={requiredFieldPlFrench}
                    placeholder="Till Name in French"
                    type="text"
                    maxLength={27}
                    size="small"
                    defaultValue={entry.tillNameFr}
                    disabled={signInClicked}
                    onChange={(e) => {
                      onChange('inputTillNameFr', e.target.value)
                    }}
                  />
                  {countCharacter}/{countMaxChar}
                  {showMSGTillF ? (
                    <Alert
                      message="Please, insert a valid Till Name"
                      type="error"
                    />
                  ) : null}
                </div>
              </FormItem>
            </Col>
          </Row>
          {/* line: 3 */}
          <Row gutter={{ xs: 16 }}>
            <Col sm={12}>
              <FormItem label="DED:">
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Select
                    placeholder="Select a DED Number"
                    defaultValue={
                      entry.ded !== ''
                        ? entry.ded
                        : '-----Select DED-----'
                    }
                    //required={requiredField}
                    disabled={signInClicked}
                    onChange={(value) => {
                      onChange('inputDed', value)
                    }}
                    size="small"
                  >
                    {dedDropDownList.map(({ key, label, value }) => (
                      <Option key={key} value={value}>
                        {key} - {label}
                      </Option>
                    ))}
                  </Select>

                  {showMSGDED ? (
                    <Alert
                      message="Please, Select a valid DED"
                      type="error"
                    />
                  ) : null}
                </div>
              </FormItem>
            </Col>
            <Col sm={12}>
              <FormItem label="Status:">
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Select
                    placeholder="Select a Coupon Status"
                    allowClear
                    defaultValue={
                      entry.status === '' ? 'Draft' : entry.status
                    }
                    // required={requiredField}
                    disabled={signInClicked}
                    size="small"
                    onChange={(value) => {
                      onChange('inputStatus', value)
                    }}
                  >
                    <Option value="Draft">Draft</Option>
                    <Option value="Upcoming">Upcoming</Option>
                    <Option value="Active">Active</Option>
                    <Option value="Eliminated">Eliminated</Option>
                  </Select>

                  {showMSGCouponStatus ? (
                    <Alert
                      message="Please, Select a valid Coupon Status"
                      type="error"
                    />
                  ) : null}
                </div>
              </FormItem>
            </Col>
          </Row>
          {/* line: 4 */}
          <Row gutter={{ xs: 16 }}>
            <Col sm={12}>
              <FormItem label="PLU:">
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Input
                    prefix=""
                    // required={requiredField}
                    placeholder="PLU Number"
                    type="number"
                    min="0"
                    maxLength={6}
                    size="small"
                    defaultValue={
                      pluNumber === 0 ? entry.plu : pluNumber
                    }
                    disabled={pluDisabledValidation}
                    onChange={(e) => {
                      onChange('inputPlu', e.target.value)
                    }}
                    onBlur={(e) => {
                      handleValidatePluDuplicate(e)
                    }}
                  />

                  {showMSGPLU ? (
                    <Alert
                      message="Please, insert a valid PLU"
                      type="error"
                    />
                  ) : null}
                </div>
              </FormItem>
            </Col>
            <Col sm={12}>
              <FormItem label="UPC:">
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Input
                    prefix=""
                    // required={requiredField}
                    placeholder="UPC Number"
                    type="number"
                    defaultValue={entry.upc}
                    value={upcNumber === 0 ? entry.upc : upcNumber}
                    disabled
                    size="small"
                    onChange={(e) => {
                      onChange('inputUpc', e.target.value)
                    }}
                  />

                  {/* {showMSGUPC
                  ? <Alert message='Please, insert a valid UPC' type='error'  />
                  : null} */}
                </div>
              </FormItem>
            </Col>
          </Row>
          {/* line: 10 */}
          <Row gutter={{ xs: 16 }}>
            <Col sm={12}>
              <FormItem label="Offer Criteria:">
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Input
                    prefix=""
                    size="small"
                    placeholder="Offer Criteria"
                    type="text"
                    defaultValue={entry.criteria}
                    disabled={signInClicked}
                    onChange={(e) => {
                      onChange('inputCriteria', e.target.value)
                    }}
                  />
                </div>
              </FormItem>
            </Col>
            <Col sm={12}>
              <FormItem label="Category:">
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Select
                    placeholder="Select a Category Option"
                    //   required
                    disabled={signInClicked}
                    defaultValue={
                      entry.couponCategory === ''
                        ? 'Select a Category'
                        : entry.couponCategory
                    }
                    allowClear
                    size="small"
                    onChange={(value) => {
                      onChange('inputCouponCategory', value)
                    }}
                  >
                    <Option value="Digital">Digital</Option>
                    <Option value="Print">Print</Option>
                    <Option value="Both">Both</Option>
                  </Select>

                  {showMSGCategory ? (
                    <Alert
                      message="Please, insert a valid Category"
                      type="error"
                    />
                  ) : null}
                </div>
              </FormItem>
            </Col>
          </Row>
          {/* line: 11 */}
          <Row gutter={{ xs: 16 }}>
            <Col sm={12}>
              <FormItem
                label="Effective Date:"
                //    required={requiredField}
              >
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Input
                    prefix=""
                    //  required={requiredField}
                    size="small"
                    placeholder="Coupon Start Date"
                    type="date"
                    defaultValue={
                      entry.startDate === ''
                        ? moment().format('YYYY-MM-DD')
                        : moment(entry.startDate).format('YYYY-MM-DD')
                    }
                    disabled={signInClicked}
                    onChange={(e) => {
                      onChange('inputStartDate', e.target.value)
                    }}
                  />

                  {showMSGEffectiveDate ? (
                    <Alert
                      message="Please, insert a valid Date"
                      type="error"
                    />
                  ) : null}
                </div>
              </FormItem>
            </Col>
            <Col sm={12}>
              <FormItem label="Inactive Date:">
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Input
                    prefix=""
                    size="small"
                    placeholder="Coupon End Date"
                    type="date"
                    defaultValue={
                      entry.endDate === ''
                        ? ''
                        : moment(entry.endDate).format('YYYY-MM-DD')
                    }
                    disabled={signInClicked}
                    onChange={(e) => {
                      onChange('inputEndDate', e.target.value)
                    }}
                  />
                </div>
              </FormItem>
            </Col>
          </Row>
          {/* line: 12 */}
          <Row gutter={{ xs: 16 }}>
            <Col sm={12}>
              <FormItem
                label="Till Instructions E:"
                //   required={requiredFieldPlEnglish}
              >
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Input
                    prefix=""
                    size="small"
                    placeholder="Till Instructions in English"
                    type="text"
                    defaultValue={entry.tillInstructionsE}
                    // required={requiredFieldPlEnglish}
                    disabled={signInClicked}
                    onChange={(e) => {
                      onChange(
                        'inputTillInstructionsE',
                        e.target.value,
                      )
                    }}
                  />

                  {showMSGTillInstE ? (
                    <Alert
                      message="Please, insert a valid Till Instructions"
                      type="error"
                    />
                  ) : null}
                </div>
              </FormItem>
            </Col>
            <Col sm={12}>
              <FormItem
                label="Till Instructions F:"
                //      required={requiredFieldPlFrench}
              >
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Input
                    prefix=""
                    placeholder="Till Instructions in French"
                    type="text"
                    size="small"
                    defaultValue={entry.tillInstructionsF}
                    //   required={requiredFieldPlFrench}
                    disabled={signInClicked}
                    onChange={(e) => {
                      onChange(
                        'inputTillInstructionsF',
                        e.target.value,
                      )
                    }}
                  />

                  {showMSGTillInstF ? (
                    <Alert
                      message="Please, insert a valid Till Instruction"
                      type="error"
                    />
                  ) : null}
                </div>
              </FormItem>
            </Col>
          </Row>
          {/* line: 13 */}
          <Row gutter={{ xs: 16 }}>
            <Col sm={12}>
              <FormItem label="Coverage:">
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Select
                    placeholder="Select a Coverage Option"
                    defaultValue={
                      entry.couponCoverage === ''
                        ? 'Select a Coverage'
                        : entry.couponCoverage
                    }
                    allowClear
                    size="small"
                    disabled={signInClicked}
                    onChange={(value) => {
                      onChange('inputCouponCoverage', value)
                    }}
                  >
                    <Option value="National">National</Option>
                    <Option value="Regional">Regional</Option>
                    <Option value="Brew Bar">Brew Bar</Option>
                  </Select>

                  {showMSGCoverage ? (
                    <Alert
                      message="Please, insert a valid Coverage"
                      type="error"
                    />
                  ) : null}
                </div>
              </FormItem>
            </Col>
            <Col sm={12}>
              <FormItem label="Region:">
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Select
                    placeholder="Select a Region"
                    defaultValue={
                      entry.region !== ''
                        ? entry.region
                        : '-----Select Region-----'
                    }
                    disabled={regionDisabled}
                    onChange={(value) => {
                      onChange('inputRegion', value)
                    }}
                    size="small"
                  >
                    {marketRegionDropDownList.map(
                      ({ key, label, value }) => (
                        <Option key={key} value={value}>
                          {value}
                        </Option>
                      ),
                    )}
                  </Select>
                </div>
              </FormItem>
            </Col>
          </Row>
          {/* line: 14 */}
          <Row gutter={{ xs: 16 }}>
            <Col sm={12}>
              <FormItem label="Programmed Language:">
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Select
                    placeholder="Select a Programmed Language"
                    disabled={signInClicked}
                    allowClear
                    defaultValue={
                      entry.language === ''
                        ? 'Select a Programmed Language'
                        : entry.language
                    }
                    size="small"
                    onChange={(value) => {
                      onChange('inputLanguage', value)
                    }}
                  >
                    <Option value="English">English</Option>
                    <Option value="French">French</Option>
                    <Option value="Both">Both</Option>
                  </Select>
                </div>
              </FormItem>
            </Col>
            <Col sm={12}>
              <FormItem label="Created:">
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Input
                    prefix=""
                    placeholder="Created Date"
                    type="text"
                    size="small"
                    defaultValue={entry.couponCreated}
                    disabled
                    onChange={(e) => {
                      onChange('inputCouponCreated', e.target.value)
                    }}
                  />
                </div>
              </FormItem>
            </Col>
          </Row>
          {/* line: 15 */}
          <Row gutter={{ xs: 16 }}>
            <Col sm={12}>
              <FormItem label="Updated Date:">
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Input
                    prefix=""
                    placeholder="Updated Date"
                    type="text"
                    size="small"
                    defaultValue={entry.updatedDate}
                    disabled
                    onChange={(e) => {
                      onChange('inputUpdatedDate', e.target.value)
                    }}
                  />
                </div>
              </FormItem>
            </Col>
            <Col sm={12}>
              <FormItem label="Updated By:">
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Input
                    prefix=""
                    placeholder="Updated By"
                    type="text"
                    size="small"
                    defaultValue={entry.updatedBy}
                    disabled
                    onChange={(e) => {
                      onChange('inputUpdatedBy', e.target.value)
                    }}
                  />
                </div>
              </FormItem>
            </Col>
          </Row>
          {/* line: 16 */}
          <Row gutter={{ xs: 16 }}>
            <Col sm={16}>
              <FormItem label="Barcode:">
                <div style={{ whiteSpace: 'nowrap', width: '650px' }}>
                  <center>
                    <Row justify="center">
                      <Col xxl={32}>
                        <Card type="inner">
                          <div
                            style={{
                              textAlign: 'center',
                              marginBottom: 0,
                              border: '11px red',
                              paddingTop: 50,
                              paddingRight: 10,
                              paddingLeft: 30,
                            }}
                            {...getRootProps()}
                          >
                            <input
                              {...getInputProps()}
                              disable="true"
                            />
                            <UploadOutlined
                              style={{
                                color: '#ff6319',
                                fontSize: '20px',
                              }}
                            />
                            <p style={{ color: '#ff6319' }}>
                              Drag 'n' drop the file here, or click to
                              select files
                            </p>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </center>

                  {showMSGBarcode ? (
                    <Alert
                      message="Please, insert a Barcode"
                      type="error"
                    />
                  ) : null}
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={{ xs: 16 }}>
            <Col>
              <center>
                {fileUploads.length > 0 ? (
                  <Table
                    style={{ width: '95%' }}
                    showHeader={false}
                    columns={[
                      {
                        title: 'File Name',
                        key: 'fileName',
                        dataIndex: 'fileName',
                        width: 'auto',
                      },
                      {
                        title: 'Download',
                        key: 'download',
                        dataIndex: 'fileId',
                        width: '150px',
                        render: (fileId) => (
                          <Button
                            onClick={() => {
                              loadBarcodeImage(fileId)
                            }}
                            disabled={signInClicked || !fileId}
                            style={{
                              backgroundColor: theme.primary,
                              color: theme.white,
                              width: 'auto',
                              marginRight: 40,
                            }}
                          >
                            {fileId
                              ? 'Download Barcode'
                              : 'Pending Upload'}
                          </Button>
                        ),
                      },
                      {
                        title: 'Action',
                        key: 'key',
                        dataIndex: 'key',
                        width: '16px',
                        render: (file, key) => (
                          <Button
                            key={key}
                            onClick={removeFile(file)}
                          >
                            {
                              <Icon
                                type="delete"
                                theme="filled"
                                style={{
                                  border: 'none',
                                  color: theme.primary,
                                  width: 'auto',
                                }}
                              />
                            }
                          </Button>
                        ),
                      },
                    ]}
                    pagination={false}
                    bordered
                    dataSource={fileUploads}
                    loading={loading}
                    size="small"
                  />
                ) : null}
              </center>
            </Col>
          </Row>
          <div
            style={{
              whiteSpace: 'nowrap',
              padding: '0px 0px 0px 350px',
            }}
          >
            <Row gutter={{ xs: 16 }}>
              <Col sm={12}>
                <FormItem
                  wrapperCol={{ ...layout.wrapperCol, offset: 8 }}
                >
                  <Button
                    htmlType="submit"
                    disabled={signInClicked}
                    style={{
                      backgroundColor: theme.primary,
                      color: theme.white,
                      width: 'auto',
                      marginRight: 40,
                    }}
                  >
                    {entry.isNewCoupon === false
                      ? 'Update Coupon'
                      : 'Create Coupon'}
                  </Button>
                </FormItem>
              </Col>
            </Row>
          </div>
          {signInClicked === true ? (
            <div className="spin" style={{ float: 'left' }}>
              <Spin size="large" />
            </div>
          ) : null}
        </Form>
      </div>
    </div>
  )
}

export default CouponFile
