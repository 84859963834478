import React, { useState, useEffect } from 'react'
import '../core/resources/style.css'
import Axios from 'agilite-utils/axios'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import MomentTZ from 'moment-timezone'
import CouponFileDashboardContainer from './couponFileDashboard-container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import {
  Layout,
  Divider,
  Icon,
  Form,
  Input,
  Select,
  InputNumber,
  Button,
  Row,
  Col,
  Spin,
  Card,
  Tooltip,
  message,
} from 'antd'

import DatePickerWeb from './reusables/date-picker-web'
import { useDispatch } from 'react-redux'

import MemoryStore from './resources/memory-store'
import theme from './resources/theme'
import { signInUser } from './resources/utilities'
import RootLoading from './root-loading'
import CoreEnums from '../core/resources/enums'

import logo from './resources/aw_logo.png'

import './resources/style.css'

const FormItem = Form.Item

const DedFile = () => {
  const dispatch = useDispatch()
  const [signInClicked, setSignInClicked] = useState(false)
  const entry = MemoryStore.dedForm
  const [hideDeleteButton, sethideDeleteButton] = useState(
    entry.isNewDed === false ? false : true,
  )
  const [changePageTitle, setchangePageTitle] = useState(
    entry.isNewDed === false ? 'Update or Delete DED' : 'Create DED',
  )
  const [changeButtonTitle, setchangeButtonTitle] = useState(
    entry.isNewDed === false ? 'Update DED' : 'Create DED',
  )

  //console.log('entryDed.isNewDed:', entry.isNewDed)

  const handleCreateDedNumber = (e) => {
    e.preventDefault()
    setSignInClicked(true)

    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      data: {
        ded: entry.ded,
        description: entry.dedName,
      },
      headers: {
        'Content-Type': 'application/json',
        type: 'ded',
        action: 'insert',
      },
    })
      .then((res) => {
        success('Success - The Ded number was created', 3)
        handleHomePage()
      })
      .catch((err) => {
        error('Error - The Ded number was not created', 3)
      })
  }

  const handleUpddateDedNumber = (e) => {
    e.preventDefault()
    setSignInClicked(true)

    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      data: {
        ded: entry.ded,
        description: entry.dedName,
      },
      headers: {
        'Content-Type': 'application/json',
        id: entry.dedId,
        type: 'ded',
        action: 'update',
      },
    })
      .then((res) => {
        success('Success - The Ded number was updated', 3)
        handleHomePage()
      })
      .catch((err) => {
        error('Error - The Ded number was not updated', 3)
      })
  }

  const handleDeleteDedNumber = (e) => {
    e.preventDefault()
    setSignInClicked(true)

    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      data: {
        ded: entry.ded,
        description: entry.dedName,
      },
      headers: {
        'Content-Type': 'application/json',
        id: entry.dedId,
        type: 'ded',
        action: 'delete',
      },
    })
      .then((res) => {
        success('Success - The Ded number was deleted', 3)
        handleHomePage()
      })
      .catch((err) => {
        error('Error - The Ded number was not deleted', 3)
      })
  }

  const handleValidateDedDuplicate = (e, isNewDed) => {
    e.preventDefault()
    setSignInClicked(true)

    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      data: {
        qry: { ded: { $eq: entry.ded } },
        qryOptions: '',
        fieldsToReturn: '',
        page: null,
        pageLimit: null,
      },
      // headers: { 'Content-Type': 'application/json', email: MemoryStore.userProfile.profile.login }
      headers: {
        'Content-Type': 'application/json',
        type: 'ded',
        action: 'get',
      },
    }).then((res) => {
      //console.log('res:', res)

      if (res.data.length > 0) {
        if (entry.ded == res.data[0].ded) {
          entry.isDedDuplicated = 0
        }
      } else {
        entry.isDedDuplicated = 1
      }

      //console.log('isNewDed:', isNewDed)
      //console.log('isDedDuplicated:', entry.isDedDuplicated)
      const isDedDuplicated = entry.isDedDuplicated

      if (isNewDed || isNewDed === '') {
        if (isDedDuplicated === 0) {
          error('Error - The Ded already exists', 3)
          setSignInClicked(false)
          return
        } else {
          handleCreateDedNumber(e)
        }
      } else {
        handleUpddateDedNumber(e)
      }
    })
  }

  const handleHomePage = (value) => {
    entry.isNewDed = ''
    entry.dedId = ''
    entry.ded = ''
    entry.dedName = ''

    dispatch({
      type: CoreEnums.reducers.LOAD_CONTENT,
      payload: {
        content: <CouponFileDashboardContainer />,
        title: 'Coupon File Dashboard',
        key: 'couponFileDashboard',
        app: 'couponFileDashboard',
        closable: false,
        custom: {},
      },
    })
  }

  const handleDedNumber = (e, isNewDed) => {
    handleValidateDedDuplicate(e, isNewDed)
    /*
    console.log('handleDedNumber')
    console.log('isNewDed:', isNewDed)
    console.log('isDedDuplicated:', entry.isDedDuplicated)
    const isDedDuplicated =  entry.isDedDuplicated


    if (isNewDed || isNewDed===''){
      if(isDedDuplicated){
        error('Error - The Ded already exists', 0)
      }else{
        handleCreateDedNumber(e)
      }
    }else{
        handleUpddateDedNumber(e)
    }
   */
  }

  const onChange = (key, value) => {
    //console.log('key:', key)
    // console.log('value:', value)

    switch (key) {
      case 'inputDedName':
        entry.dedName = value
        break
      case 'inputDed':
        entry.ded = value
        break
      default:
    }
  }

  const success = (msg, duration) => {
    message.success(msg, duration)
  }

  const error = (msg, duration) => {
    message.error(msg, duration)
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 6 },
  }

  return (
    <div>
      <div
        style={{
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block',
        }}
      >
        <a href="https://www.aw.ca">
          <Icon component={() => <img src={logo} />} />
        </a>
      </div>
      <div
        className="container p-3 my-3 border shadow p-3 mb-5 rounded"
        style={{ background: '#fff', boarder: 'rounded' }}
      >
        <div style={{ padding: '0px 0px 0px 500px' }}>
          <FontAwesomeIcon
            icon={faHome}
            style={{
              color: '#ff6319',
              height: '30px',
              width: '30px',
              cursor: 'pointer',
            }}
            onClick={() => handleHomePage()}
          />
        </div>
        <h2
          style={{ color: '#ff6319', padding: '0px 0px 0px 400px' }}
        >
          Coupon File - {changePageTitle}
        </h2>
        <Form
          {...layout}
          className="site-layout-content"
          onSubmit={(e) => {
            handleDedNumber(e, entry.isNewDed)
          }}
          size="small"
          layout="horizontal"
        >
          <Row gutter={{ xs: 16 }}>
            <Col sm={8}>
              <FormItem label="Ded:" required={true}>
                <div style={{ whiteSpace: 'nowrap', width: '150px' }}>
                  <Input
                    //prefix={<Icon type='mail' style={{ color: theme.secondaryDark }} />}
                    prefix=""
                    required
                    placeholder="Ded Number"
                    type="number"
                    defaultValue={entry.ded}
                    disabled={signInClicked}
                    min="0"
                    onChange={(e) => {
                      onChange('inputDed', e.target.value)
                    }}
                  />
                </div>
              </FormItem>
            </Col>
            <Col sm={12}>
              <FormItem label="Ded Name:" required={true}>
                <div style={{ whiteSpace: 'nowrap', width: '250px' }}>
                  <Input
                    //prefix={<Icon type='mail' style={{ color: theme.secondaryDark }} />}
                    prefix=""
                    required
                    placeholder="Ded Name"
                    type="text"
                    defaultValue={entry.dedName}
                    disabled={signInClicked}
                    onChange={(e) => {
                      onChange('inputDedName', e.target.value)
                    }}
                  />
                </div>
              </FormItem>
            </Col>
          </Row>
          <div
            style={{
              whiteSpace: 'nowrap',
              padding: '0px 0px 0px 400px',
            }}
          >
            <Row gutter={{ xs: 8 }}>
              <Col sm={6}>
                <FormItem>
                  <Button
                    htmlType="submit"
                    disabled={signInClicked}
                    style={{
                      backgroundColor: theme.primary,
                      color: theme.white,
                    }}
                  >
                    {changeButtonTitle}
                  </Button>
                </FormItem>
              </Col>
              <Col sm={6}>
                <FormItem>
                  {hideDeleteButton === false ? (
                    <Button
                      htmlType="button"
                      disabled={signInClicked}
                      style={{
                        backgroundColor: theme.primary,
                        color: theme.white,
                      }}
                      onClick={(e) => {
                        handleDeleteDedNumber(e)
                      }}
                    >
                      Delete DED
                    </Button>
                  ) : null}
                </FormItem>
              </Col>
            </Row>
          </div>
          {signInClicked === true ? (
            <div className="spin" style={{ float: 'left' }}>
              <Spin size="large" />
            </div>
          ) : null}
        </Form>
      </div>
    </div>
  )
}

export default DedFile
