import React from 'react'
import Axios from 'agilite-utils/axios'
import {
  Input,
  Row,
  Col,
  Icon,
  Button,
  Form,
  Divider,
  Select,
  Radio,
  DatePicker,
  Alert,
  message,
} from 'antd'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'antd/dist/antd.css'
import '../core/resources/style.css'
import moment from 'moment'
import './resources/style.css'
import logo from './resources/aw_logo.png'
import PromoCodeDashboard from './promoCodeDashboard-Container'
import CouponFileDashboard from './couponFileDashboard-container'
import { HomeOutlined, LeftCircleOutlined } from '@ant-design/icons'

const Option = Select.Option

class PromoCode extends React.Component {
  constructor(props) {
    super(props)
    console.log('welcome props: ', props)
    this.state = {
      plu: '',
      couponList: [],
      regionMKT: [],
      couponNameEn: '',
      promoCodeEn: '',
      promoCodeFr: '',
      startDate: '',
      endDate: '',
      coverage: '',
      region: '',
      status: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      showMyComponent: true,
      showMSGPLU: false,
      showMSGcoupName: false,
      showMSGPromoEn: false,
      showMSGPromoFr: false,
      showMSGStartDate: false,
      showMSGEndDate: false,
      showMSGCoverage: false,
      showMSGRegions: false,
      showMSGCreateDate: false,
      showMSGCreateBy: false,
      showMSGUpdateDate: false,
      showMSGUpdateBy: false,
      showMSGStatus: false,
      isUpdate: false,
      axiosAction: '',
      axiosId: '',
    }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.handleCreatePromo = this.handleCreatePromo.bind(this)
    this.handleHome = this.handleHome.bind(this)
    this.handleValidation = this.handleValidation.bind(this)
    this.handleCouponDashboard = this.handleCouponDashboard.bind(this)
  }

  componentDidMount(props) {
    // getting coupons information
    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      data: {
        fieldsToReturn: 'couponNameEn plu',
      },
      headers: {
        'Content-Type': 'application/json',
        type: 'coupon',
        action: 'get',
      },
    }).then((res) => {
      this.setState({
        couponList: res.data,
      })
    })

    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
      headers: {
        'Content-Type': 'application/json',
        type: 'mktRegion',
        action: 'get',
      },
    }).then((res) => {
      this.setState({
        regionMKT: res.data,
      })
    })

    if (this.props.data.value) {
      console.log('props: ', this.props)
      this.setState({
        couponNameEn: this.props.data.value.couponNameEn,
        coverage: this.props.data.value.coverage,
        createdBy: this.props.data.value.createdBy,
        createdDate: this.props.data.value.createdDate,
        endDate: this.props.data.value.endDate,
        globalSearch: this.props.data.value.globalSearch,
        plu: this.props.data.value.plu,
        promoCodeEn: this.props.data.value.promoCodeEn,
        promoCodeFr: this.props.data.value.promoCodeFr,
        region: this.props.data.value.region,
        startDate: this.props.data.value.startDate,
        updatedBy: this.props.data.value.updatedBy,
        updatedDate: this.props.data.value.updatedDate,
        axiosId: this.props.data.value._id,
        axiosAction: 'update',
      })
    } else {
      console.log('props empty: ', this.props.data.value)
      this.setState({
        axiosAction: 'insert',
        axiosId: '',
      })
    }
  }

  handleCreatePromo() {
    const key = 'updatable'

    message.loading({ content: 'Loading...', key })

    if (this.handleValidation()) {
      Axios({
        method: 'post',
        url: `${process.env.REACT_APP_NODE_RED_URL}/api/aw/couponhub/data`,
        data: {
          plu: this.state.plu,
          couponNameEn: this.state.couponNameEn,
          promoCodeEn: this.state.promoCodeEn,
          promoCodeFr: this.state.promoCodeFr,
          startDate: moment(this.state.startDate),
          endDate: moment(this.state.endDate),
          coverage: this.state.coverage,
          region: this.state.region,
          createdDate: moment(this.state.createdDate),
          createdBy: this.state.createdBy,
          updatedDate: this.state.updatedDate,
          updatedBy: this.state.updatedBy,
        },
        headers: {
          'Content-Type': 'application/json',
          id: this.state.axiosId,
          type: 'promo',
          action: this.state.axiosAction,
        },
      }).then((res) => {
        console.log('inserted: ', res)
        setTimeout(() => {
          message.success({
            content: 'The document was saved!',
            key,
            duration: 2,
          })
        }, 1000)
        this.handleHome()
      })
    }
  }

  handleValidation() {
    let ret = true

    if (this.state.plu === '') {
      this.setState({ showMSGPLU: true })
      ret = false
    } else {
      this.setState({ showMSGPLU: false })
    }

    if (this.state.couponList === '') {
      this.setState({ showMSGcoupName: true })
      ret = false
    } else {
      this.setState({ showMSGcoupName: false })
    }

    if (
      this.state.promoCodeEn === '' &&
      this.state.promoCodeFr === ''
    ) {
      this.setState({
        showMSGPromoEn: true,
        showMSGPromoFr: true,
      })
      ret = false
    } else {
      this.setState({
        showMSGPromoEn: false,
        showMSGPromoFr: false,
      })
    }

    if (this.state.startDate === '') {
      this.setState({ showMSGStartDate: true })
      ret = false
    } else {
      this.setState({ showMSGStartDate: false })
    }

    if (this.state.endDate === '') {
      this.setState({ showMSGEndDate: true })
      ret = false
    } else {
      this.setState({ showMSGEndDate: false })
    }

    if (this.state.coverage === '') {
      this.setState({ showMSGCoverage: true })
      ret = false
    } else {
      this.setState({ showMSGCoverage: false })
    }

    if (this.state.region === '') {
      this.setState({ showMSGRegions: true })
      ret = false
    } else {
      this.setState({ showMSGRegions: false })
    }

    if (this.state.createdDate === '') {
      this.setState({ showMSGCreateDate: true })
      ret = false
    } else {
      this.setState({ showMSGCreateDate: false })
    }

    if (this.state.createdBy === '') {
      this.setState({ showMSGCreateBy: true })
      ret = false
    } else {
      this.setState({ showMSGCreateBy: false })
    }

    if (this.state.updatedDate === '') {
      this.setState({ showMSGUpdateDate: true })
      ret = false
    } else {
      this.setState({ showMSGUpdateDate: false })
    }

    if (this.state.updatedBy === '') {
      this.setState({ showMSGUpdateBy: true })
      ret = false
    } else {
      this.setState({ showMSGUpdateBy: false })
    }

    if (this.state.status === '') {
      this.setState({ showMSGStatus: true })
      ret = false
    } else {
      this.setState({ showMSGStatus: false })
    }

    return ret
  }

  handleHome() {
    this.props.callDashboard(<PromoCodeDashboard data={{}} />)
  }

  handleCouponDashboard() {
    this.props.callDashboard(<CouponFileDashboard data={{}} />)
  }
  loadListPlu(dataPayload) {
    const pluNumberFilterList = []
    const dupCheck = {}
    const tmpThis = this
    let dataEntry = null

    for (const x in dataPayload) {
      dataEntry = dataPayload[x]

      if (!dataEntry.plu) {
        dataEntry.plu = 0
      }

      if (!dupCheck[dataEntry.plu]) {
        dupCheck[dataEntry.plu] = dataEntry.plu
        pluNumberFilterList.push({
          text: dataEntry.plu,
          value: dataEntry.plu,
        })
      }
    }
    tmpThis.setState({ pluNumberFilterList })
  }

  handleOnBlur(event) {
    const date = new Date(event.target.value)
    console.log('Loading Feedback Level', date)
  }

  handleOnChange(field, value) {
    console.log('onchange: ', field, value)
    this.setState({
      field: value,
    })
  }

  handleOnClick() {
    console.log('submited: ', this.state.promoCodeEn)
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    }

    // edu
    return (
      <div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <a href="https://www.aw.ca">
            <Icon component={() => <img src={logo} />} />
          </a>
        </div>
        <div
          className="container p-3 my-3 border shadow p-3 mb-5 rounded"
          style={{ background: '#fff', boarder: 'rounded' }}
        >
          <h3 style={{ color: '#ff6319' }}>
            <HomeOutlined
              onClick={() => this.handleCouponDashboard()}
            />
          </h3>

          {/* this is to back to promocodedashboard -  need to refine the logic */}
          {/* <h3 style={{ color: '#ff6319' }}><LeftCircleOutlined  onClick={() => this.handleHome()} /></h3> */}

          <h2 className="ant-col-title">New Promo Code </h2>
          <div
            className="jumbotron"
            style={{
              background: '#fff',
              paddingLeft: '15px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            <Divider />

            <Form
              {...formItemLayout}
              hasFeedback
              size="middle"
              name="basic"
              initialValues={{ remember: true }}
            >
              <Row justify="center" align="center">
                <Col span={12}>
                  <Form.Item label="PLU" name="plu">
                    <Select
                      value={this.state.plu}
                      onChange={(e) => {
                        this.setState({ plu: e })
                      }}
                    >
                      {this.state.couponList.map((entry) => (
                        <Select.Option
                          key={entry._id}
                          value={entry.plu}
                          onClick={() =>
                            this.setState({
                              couponNameEn: entry.couponNameEn,
                            })
                          }
                        >
                          {entry.plu}
                        </Select.Option>
                      ))}
                    </Select>
                    {this.state.showMSGPlu ? (
                      <Alert
                        message="Please, select a valid PLU"
                        type="error"
                        showIcon
                      />
                    ) : null}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Coupon Name" name="couponNameEn">
                    <Select
                      value={this.state.couponNameEn}
                      onChange={(e) => {
                        this.setState({ couponNameEn: e })
                      }}
                    >
                      {this.state.couponList.map((entry) => (
                        <Select.Option
                          key={entry._id}
                          value={entry.couponNameEn}
                          onClick={() =>
                            this.setState({ plu: entry.plu })
                          }
                        >
                          {entry.couponNameEn}
                        </Select.Option>
                      ))}
                    </Select>
                    {this.state.showMSGcoupName ? (
                      <Alert
                        message="Please, select a valid Coupon Name"
                        type="error"
                        showIcon
                      />
                    ) : null}
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center" align="center">
                <Col span={12}>
                  <Form.Item label="Promo Code EN" name="promoCodeEn">
                    <Input
                      value={this.state.promoCodeEn}
                      onChange={(e) => {
                        this.setState({ promoCodeEn: e.target.value })
                      }}
                    />
                    {this.state.showMSGPromoEn ? (
                      <Alert
                        message="Please, insert a Promo Code description"
                        type="error"
                        showIcon
                      />
                    ) : null}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Promo Code FR" name="promoCodeFr">
                    <Input
                      value={this.state.promoCodeFr}
                      onChange={(e) => {
                        this.setState({ promoCodeFr: e.target.value })
                      }}
                    />
                    {this.state.showMSGPromoFr ? (
                      <Alert
                        message="Please, insert a Promo Code description"
                        type="error"
                        showIcon
                      />
                    ) : null}
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center" align="center">
                <Col span={12}>
                  <Form.Item label="Start Date" name="startDate">
                    <DatePicker
                      value={moment(this.state.startDate)}
                      onChange={(e) => {
                        this.setState({
                          startDate: moment(e).format('MM/DD/YYYY'),
                        })
                      }}
                    />
                    {this.state.showMSGStartDate ? (
                      <Alert
                        message="Please, insert a valid date"
                        type="error"
                        showIcon
                      />
                    ) : null}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="End Date" name="endDate">
                    <DatePicker
                      value={moment(this.state.endDate)}
                      style={{ width: '100%' }}
                      onChange={(e) => {
                        this.setState({
                          endDate: moment(e).format('MM/DD/YYYY'),
                        })
                      }}
                    />
                    {this.state.showMSGEndDate ? (
                      <Alert
                        message="Please, insert vlida date"
                        type="error"
                        showIcon
                      />
                    ) : null}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label="Coverage" name="coverage">
                    <Select
                      value={this.state.coverage}
                      onChange={(e) => {
                        this.setState({ coverage: e.target.value })
                      }}
                    >
                      <Option value="National">National</Option>
                      <Option value="Regional">Regional</Option>
                      <Option value="Brew Bar">Brew Bar</Option>
                    </Select>
                    {this.state.showMSGCoverage ? (
                      <Alert
                        message="Please, select a valid Coverage"
                        type="error"
                        showIcon
                      />
                    ) : null}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Regions" name="regions">
                    <Select
                      value={this.state.region}
                      onChange={(e) => {
                        this.setState({ region: e })
                      }}
                    >
                      {this.state.regionMKT.map((entry) => (
                        <Select.Option
                          key={entry._id}
                          value={entry.NAME}
                          onClick={() =>
                            this.setState({ region: entry.NAME })
                          }
                        >
                          {entry.NAME}
                        </Select.Option>
                      ))}
                    </Select>
                    {this.state.showMSGRegions ? (
                      <Alert
                        message="Please, select a valid Region"
                        type="error"
                        showIcon
                      />
                    ) : null}
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="center" align="center">
                <Col span={12} />
                <Col span={12}>
                  <Form.Item label="Status" name="status">
                    <Input
                      value={this.state.status}
                      onChange={(e) => {
                        this.setState({ status: e.target.value })
                      }}
                    />
                    {this.state.showMSGStatus ? (
                      <Alert
                        message="Please, insert the status"
                        type="error"
                        showIcon
                      />
                    ) : null}
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center" align="center">
                <Col span={12}>
                  <Form.Item label="Updated Date" name="updatedDate">
                    <DatePicker
                      value={moment(this.state.updatedDate)}
                      style={{ width: '100%' }}
                      onChange={(e) => {
                        this.setState({
                          updatedDate: moment(e).format('MM/DD/YYYY'),
                        })
                      }}
                    />
                    {this.state.showMSGUpdateDate ? (
                      <Alert
                        message="Please, insert a Updated data"
                        type="error"
                        showIcon
                      />
                    ) : null}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Updatad By" name="updatedBy">
                    <Input
                      value={this.state.updatedBy}
                      onChange={(e) => {
                        this.setState({ updatedBy: e.target.value })
                      }}
                    />
                    {this.state.showMSGUpdateBy ? (
                      <Alert
                        message="Please, insert the update by field"
                        type="error"
                        showIcon
                      />
                    ) : null}
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center" align="center">
                <Col span={12}>
                  <Form.Item label="Created Date" name="createdDate">
                    <DatePicker
                      value={moment(this.state.createdDate)}
                      style={{ width: '100%' }}
                      onChange={(e) => {
                        this.setState({
                          createdDate: moment(e).format('MM/DD/YYYY'),
                        })
                      }}
                    />
                    {this.state.showMSGCreateDate ? (
                      <Alert
                        message="Please, insert valid Date"
                        type="error"
                        showIcon
                      />
                    ) : null}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Created By" name="createdBy">
                    <Input
                      value={this.state.createdBy}
                      onChange={(e) => {
                        this.setState({ createdBy: e.target.value })
                      }}
                    />
                    {this.state.showMSGCreateBy ? (
                      <Alert
                        message="Please, insert value to created by"
                        type="error"
                        showIcon
                      />
                    ) : null}
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center" align="center">
                <Col span={24} style={{ textAlign: 'right' }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="small"
                      style={{
                        color: '#ff6319',
                        backgroundColor: 'transparent',
                        borderColor: '#ff6319',
                      }}
                      onClick={() => this.handleCreatePromo()}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}
export default PromoCode
