import Agilite from 'agilite'

import CoreEnums from '../../core/resources/enums'
import MemoryStore from './memory-store'
import CoreMemoryStore from '../../core/core-memory-store'
import {v1} from 'agilite-utils/uuid'


export const editDocument = (record, Content) => {
  return dispatch => {
    dispatch({
      type: CoreEnums.reducers.LOAD_CONTENT,
      payload: {
        content: Content,
        title: 'Test Record',
        key: record._id,
        app: record._id,
        closable: false,
        custom: {}
      }
    })
  }
}

export const createDocument = (Content) => {
  const uuid = v1()

  return dispatch => {
    dispatch({
      type: CoreEnums.reducers.LOAD_CONTENT,
      payload: {
        content: Content,
        title: 'New Record',
        key: uuid,
        app: uuid,
        closable: false,
        custom: {}
      }
    })
  }
}

export const callDashboard = (Content) => {
  return dispatch => {
    dispatch({
      type: CoreEnums.reducers.LOAD_CONTENT,
      payload: {
        content: Content,
        title: 'Guest Feedback Dashboard',
        key: 'guestFeedbackDashboard',
        app: 'guestFeedbackDashboard',
        closable: false,
        custom: {}
      }
    })
  }
}

export const createDedNumber = (Content) => {
  const uuid = v1()

  return dispatch => {
    dispatch({
      type: CoreEnums.reducers.LOAD_CONTENT,
      payload: {
        content: Content,
        title: 'New Ded Record',
        key: uuid,
        app: uuid,
        closable: false,
        custom: {}
      }
    })
  }
}

// export const signOutUser = callback => {
//   return dispatch => {
//     // TODO: const tmpState = JSON.parse(JSON.stringify(AppConfig))

//     tmpState.loggedIn = false
//     // TODO: tmpState.rootContent = SignInContainer

//     dispatch({
//       type: CoreEnums.reducers.SET_STATE,
//       state: tmpState
//     })
//   }
// }

export const signInUser = callback => {
  const entry = MemoryStore.signInForm
  let payload = null

  const config = {
    apiServerUrl: CoreMemoryStore.agilite.apiServerUrl,
    apiKey: CoreMemoryStore.agilite.apiKey
  }

  if (!MemoryStore.agilite) {
    MemoryStore.agilite = new Agilite(config)
  }

  payload = {
    username: entry.email.toLowerCase(),
    password: entry.password
  }

  //console.log('payload', payload)

  MemoryStore.agilite.Connectors.execute('okta', 'login', payload)
    .then((result) => {
      //console.log('success', result.data)
      callback(null, result.data)
    })
    .catch((err) => {
      if (err.response) {
        err = err.response.data || err.response
      }

      callback(err)
    })
}

export const setToolbar = (toolbarState) => {
  return dispatch => {
    dispatch({ type: CoreEnums.reducers.SET_TOOLBAR, payload: toolbarState })
  }
}
