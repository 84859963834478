const memoryStore = {
  agilite: null,
  userProfile: null,
  signInForm: {
    email: '',
    password: ''
  },
  couponForm: {
    couponId: '',
    couponNameEn: '',
    couponNameFr: '',
    tillNameEn: '',
    tillNameFr: '',
    ded: '',
    upc: '',
    plu: '',
    couponCreated: '',
    couponCode: '',
    baseCVID: '',
    percentOffOrder: '',
    percentOffItem: '',
    dollarOffOrder: '',
    dollarOffItem: '',
    itemSetPrice: '',
    cvidCombined: '',
    replacementCVID: '',
    additionalCVID: '',
    couponCategory: '',
    startDate: '',
    endDate: '',
    tillInstructionsE: '',
    tillInstructionsF: '',
    region: '',
    criteria: '',
    language: '',
    fileId: '',
    status: '',
    previousStatus: '',
    globalSearch: '',
    updatedDate: '',
    updatedBy: '',
    isNewCoupon: '',
    dedDropDownList: [],
    marketRegionDropDownList: [],
    couponJson: '',
    dedName: '',
    couponCoverage: '',
    dedNumberString: '',
    fileName: '',
    fileAttachments: [],
    sendNotification: '',
  },
  dedForm: {
    dedId: '',
    ded: '',
    dedName: '',
    isNewDed: '',
    isDedDuplicated: '',
  },
}

export default memoryStore
