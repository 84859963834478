import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Form,
  Icon,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  message,
} from 'antd'

import MemoryStore from './resources/memory-store'
import theme from './resources/theme'
import { signInUser } from './resources/utilities'
import RootLoading from './root-loading'
import CoreEnums from '../core/resources/enums'

const FormItem = Form.Item

const SignIn = () => {
  const dispatch = useDispatch()
  const [signInClicked, setSignInClicked] = useState(false)
  const entry = MemoryStore.signInForm

  const handleSignIn = (e) => {
    e.preventDefault()
    setSignInClicked(true)

    signInUser((err, result) => {
      setSignInClicked(false)

      if (err) {
        if (err.errorMessage) {
          if (
            err.errorMessage.status &&
            err.errorMessage.status === 401
          ) {
            err = 'Incorrect Username or Password. Please try again'
          } else {
            err = err.errorMessage
          }
        }

        message.error(err)
      } else {
        MemoryStore.userProfile = result._embedded.user

        dispatch({
          type: CoreEnums.reducers.LOAD_CONTENT,
          payload: {
            content: <RootLoading />,
            title: 'Loading',
            key: 'loading',
            app: 'loading',
            closable: false,
            custom: {},
          },
        })
      }
    })
  }

  const onChange = (key, value) => {
    switch (key) {
      case 'inputEmail':
        entry.email = value
        break
      case 'inputPassword':
        entry.password = value
        break
      default:
    }
  }

  return (
    <div>
      <Row type="flex" justify="center">
        <Col xs={24} sm={16} md={12} lg={8}>
          <Card
            title="Sign in below to access the Portal"
            type="inner"
          >
            <Form className="login-form" onSubmit={handleSignIn}>
              <FormItem>
                <Input
                  prefix={
                    <Icon
                      type="mail"
                      style={{ color: theme.secondaryDark }}
                    />
                  }
                  type="email"
                  placeholder="Email"
                  defaultValue={entry.email}
                  disabled={signInClicked}
                  required
                  onChange={(e) => {
                    onChange('inputEmail', e.target.value)
                  }}
                />
              </FormItem>
              <FormItem>
                <Input
                  prefix={
                    <Icon
                      type="lock"
                      style={{ color: theme.secondaryDark }}
                    />
                  }
                  required
                  placeholder="Password"
                  type="password"
                  defaultValue={entry.password}
                  disabled={signInClicked}
                  onChange={(e) => {
                    onChange('inputPassword', e.target.value)
                  }}
                />
              </FormItem>
              <Row type="flex" justify="center">
                <Col xs={24} sm={16} md={12} lg={8}>
                  <FormItem style={{ float: 'left' }}>
                    <Button
                      htmlType="submit"
                      disabled={signInClicked}
                      style={{
                        backgroundColor: theme.primary,
                        color: theme.white,
                        width: 'auto',
                        marginRight: 10,
                      }}
                    >
                      Sign In
                    </Button>
                  </FormItem>
                  {signInClicked === true ? (
                    <div className="spin" style={{ float: 'left' }}>
                      <Spin size="large" />
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default SignIn
