import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Menu } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHome,
  faAlignJustify,
  faArrowRight,
  faCog,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons'

import CoreEnums from '../core/resources/enums'

const SubMenu = Menu.SubMenu

export default function LeftMenu(props) {
  const dispatch = useDispatch()
  const rootTabTitle = useSelector(
    (state) => state.core.tabNavigation.rootTabTitle,
  )
  const rootContent = useSelector((state) => state.core.rootContent)

  function handleLeftMenu(e) {
    const key = e.key
    let Content = null
    //console.log('leftMenu: key: ', key)
    // MODULECONDITION
    switch (key) {
      case CoreEnums.values.CORE_ROOT:
        Content = rootContent

        dispatch({
          type: CoreEnums.reducers.LOAD_CONTENT,
          payload: {
            content: <Content />,
            title: rootTabTitle,
            key,
            app: key,
            closable: false,
            custom: {},
          },
        })

        break
      case 'couponFile':
        Content = require('./couponFile').default

        dispatch({
          type: CoreEnums.reducers.LOAD_CONTENT,
          payload: {
            content: <Content />,
            title: 'Coupon File',
            key,
            app: key,
            closable: true,
            handleOnEdit: (targetKey, callback) => {
              const result = true
              callback(result)
            },
            custom: {},
          },
        })

        break
      case 'dedNumber':
        Content = require('./dedNumber').default

        dispatch({
          type: CoreEnums.reducers.LOAD_CONTENT,
          payload: {
            content: <Content />,
            title: 'Ded Number',
            key,
            app: key,
            closable: true,
            handleOnEdit: (targetKey, callback) => {
              const result = true
              callback(result)
            },
            custom: {},
          },
        })

        break

      case 'couponFileDashboard':
        Content = require('./couponFileDashboard').default

        dispatch({
          type: CoreEnums.reducers.LOAD_CONTENT,
          payload: {
            content: <Content />,
            title: 'Coupon File Dashboard',
            key,
            app: key,
            closable: true,
            handleOnEdit: (targetKey, callback) => {
              const result = true
              callback(result)
            },
            custom: {},
          },
        })

        break
      case 'reset':
        dispatch({
          type: CoreEnums.reducers.RESET,
        })

        break
      default:
        // Do Nothing
        return null
    }

    props.handleCloseLeftMenu()
  }

  return (
    <Menu
      onClick={handleLeftMenu}
      style={{ width: 250 }}
      // defaultOpenKeys={['subMenu1']}
      mode="inline"
    >
      <Menu.Item key={CoreEnums.values.CORE_ROOT}>
        <FontAwesomeIcon icon={faHome} /> {rootTabTitle}
      </Menu.Item>
      <SubMenu
        key="couponFile"
        title={
          <span>
            <FontAwesomeIcon icon={faAlignJustify} />{' '}
            <span>Coupon File</span>
          </span>
        }
      >
        <Menu.Item key="couponFile">
          <FontAwesomeIcon icon={faArrowRight} /> CouponFile
        </Menu.Item>
        <Menu.Item key="dedNumber">
          <FontAwesomeIcon icon={faArrowRight} /> Ded Number
        </Menu.Item>
        <Menu.Item key="couponFileDashboard">
          <FontAwesomeIcon icon={faArrowRight} /> Coupon File
          Dashboard
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="subMenu2"
        title={
          <span>
            <FontAwesomeIcon icon={faCog} />{' '}
            <span>Administration</span>
          </span>
        }
      >
        <Menu.Item key="reset">
          <FontAwesomeIcon icon={faFileAlt} /> Sign Out
        </Menu.Item>
      </SubMenu>
    </Menu>
  )
}
