import { connect } from 'react-redux'
import PromoCodeDashboard from './promoCodeDashboard'

import {
  editDocument,
  createDocument,
  createDedNumber,
} from './resources/utilities'

const mapDispatchToProps = (dispatch) => {
  return {
    editDocument: (record, Content) => {
      dispatch(editDocument(record, Content))
    },
    createDocument: (Content) => {
      dispatch(createDocument(Content))
    },
    createDedNumber: (Content) => {
      dispatch(createDedNumber(Content))
    },
  }
}

export default connect(null, mapDispatchToProps)(PromoCodeDashboard)
