import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import Moment from 'moment'
import en from 'date-fns/locale/en-CA'
import fr from 'date-fns/locale/fr-CA'
import 'react-datepicker/dist/react-datepicker.css'

registerLocale('en', en)
registerLocale('fr', fr)

const DatePickerWeb = (props) => {
  const today = new Date()
  const locale = props.locale || 'en'
  const dateValue =
    props.currentValue === ''
      ? ''
      : Moment(props.currentValue).toDate()

  return (
    <DatePicker
      className="form-control form-control-sm"
      style={{ paddingTop: '5px' }}
      selected={dateValue}
      value={dateValue}
      onChange={(date) => props.onHandleOnChange(date)}
      showTimeSelect
      timeIntervals={15}
      timeCaption="time"
      dateFormat="yyyy-MM-dd HH:mm"
      maxDate={today}
      locale={locale}
    />
  )
}

export default DatePickerWeb
